import { css } from '@/core/theme';
import { NAVBAR_HEIGHT } from '@/common/constants';

const root = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  top: NAVBAR_HEIGHT,
  zIndex: 4,
});

const instructions = css({

  variants: {

    isDrawing: {

      true: {
        opacity: 0,
      },

      false: {
        opacity: 1,
      },
    },
  },
  
  width: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.85)',
  padding: '$medium $small',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  columnGap: '$small',
  transition: 'opacity .25s',
});

const actions = css({

  variants: {

    isDrawing: {

      true: {
        transform: `translate(-50%, -${NAVBAR_HEIGHT})`,
      },

      false: {
        transform: 'translate(-50%, 0)',
      },
    },

    isDrawableOrClearable: {

      true: { },

      false: {
        top: '$xl',
      },
    },

    forceVisible: {
      true: {
        opacity: 1,
        visibility: 'visible',
        transform: 'translate(-50%, 0) !important',
      },
    },
  },

  position: 'absolute',
  top: 'calc($xxl + $xl)',
  left: '50%',
  transition: 'transform .25s',
});

export const styles = {
  root,
  instructions,
  actions,
};
