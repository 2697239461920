<template>
  <div class="consulta-dados-quota">
    <h3 class="consulta-dados-quota-title">
      Consultas
    </h3>
    <span
      v-if="consultaDadosQuota"
      class="consulta-dados-quota-description"
    >
      Você usou
      <b>{{ consultaDadosQuota.total - consultaDadosQuota.available }}</b>
      de {{ consultaDadosQuota.total }} consultas disponíveis
    </span>
    <v-progress-linear
      class="consulta-dados-quota-bar"
      :value="consultaDadosQuotaPercentage"
      height="10"
      rounded
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ConsultaDadosQuota',
  created () {
    this.fetchConsultaDadosQuota();
  },
  computed: {
    ...mapState('user', ['consultaDadosQuota']),
    consultaDadosQuotaPercentage () {
      if (!this.consultaDadosQuota) return 0;

      return 100 - (this.consultaDadosQuota.available * 100) / this.consultaDadosQuota.total;
    },
  },
  methods: {
    ...mapActions({
      fetchConsultaDadosQuota: 'user/fetchConsultaDadosQuota',
    }),
  },
};
</script>

<style lang="scss" scoped>
.consulta-dados-quota {
  background-color: #eceff8;
  @apply w-full;
  padding: 10px;
  margin-bottom: 30px;
  &-title {
    @apply font-bold;
    font-size: 14px;
  }
  &-description {
    @apply text-sm;
    margin-top: 12px;
    font-size: 12px;
  }
  &-bar {
    margin-top: 12px;
  }
  ::v-deep.v-progress-linear {
    &__determinate {
      border-radius: 6px;
    }
    &__background {
      left: 0 !important;
      width: 100% !important;
    }
  }
}
</style>
