<template>
  <div
    v-if="iptuDescription && iptuDescription.areaTerreno"
    class="iptu"
  >
    <div class="flex w-full">
      <h2 class="iptu-title">
        Dados IPTU
      </h2>
    </div>
    <div class="typology">
      <ul class="align-center w-full flex p-0">
        
        <li
          v-if="property"
          class="divider first"
        />
        <!-- ano de construção -->
        <li>
          <Typography
            variant="title-large-secondary"
            class="title-iptu-dados"
            color="neutral-grey-5"
            tag="p"
          >
            {{ iptuDescription.anoConstrucao  }}
          </Typography> 
          <Typography
            variant="helper"
            color="neutral-grey-5"
            tag="span"
          >
            Ano de construção
          </Typography> 
        </li>
        <li
          v-if="property"
          class="divider"
        />
         <!-- pavimentos -->
        <li class="relative text-center">
          <Typography
            variant="title-large-secondary"
            class="title-iptu-dados"
            color="neutral-grey-5"
            tag="p"
          >
            {{ iptuDescription.quantidadePavimentos }} + Térreo
          </Typography>
          <Typography
            variant="helper"
            color="neutral-grey-5"
            tag="span"
          >
            Pavimento{{ iptuDescription.quantidadePavimentos > 1 ? 's' : '' }}
          </Typography>
        </li>
        <li
          v-if="property"
          class="divider"
        />
        <!-- unidades -->
        <li
          v-if="iptuDescription.unidades && !this.isTypologyHouse()"
        >
          <Typography
            variant="title-large-secondary"
            class="title-iptu-dados"
            color="neutral-grey-5"
            tag="p"
          >
            {{ iptuDescription.unidades }}
          </Typography> 
          <Typography
            variant="helper"
            color="neutral-grey-5"
            tag="span"
          >
            Unidade{{ iptuDescription.unidades > 1 ? 's' : '' }}
          </Typography>
        </li>
        <li
          v-if="property && !this.isTypologyHouse()"
          class="divider"
        />
        <!-- area construida  -->
        <li>
          <Typography
            variant="title-large-secondary"
            class="title-iptu-dados"
            color="neutral-grey-5"
            tag="p"
          >
          {{ 
            iptuDescription.tipologia === 'Casa' 
              ? iptuDescription.testada 
              : `De ${formatarMilhar(iptuDescription.areaConstruidaMin)} a ${formatarMilhar(iptuDescription.areaConstruidaMax)} `
            }} m<sup>2</sup>
          </Typography> 
          <Typography
            variant="helper"
            color="neutral-grey-5"
            tag="span"
          >
            Área Construída
          </Typography>
        </li>
        <li
          v-if="property"
          class="divider"
        />
        <!-- area do terreno -->
        <li>
          <Typography
            variant="title-large-secondary"
            class="title-iptu-dados"
            color="neutral-grey-5"
            tag="p"
          >
            {{ formatarMilhar(iptuDescription.areaTerreno) }} m<sup>2</sup>
          </Typography>
          <Typography
            variant="helper"
            color="neutral-grey-5"
            tag="span"
          >
            Área do terreno
          </Typography>
        </li>
        <li
          v-if="property"
          class="divider"
        />
        <!-- tipo de terreno -->
        <li>
          <Typography
            variant="title-large-secondary"
            class="title-iptu-dados"
            color="neutral-grey-5"
            tag="p"
          >
            {{ iptuDescription.tipoTerreno }}
          </Typography>
          <Typography
            variant="helper"
            color="neutral-grey-5"
            tag="span"
          >
            Tipo de terreno
          </Typography>
        </li>
        <li
          v-if="property"
          class="divider"
        />
        <!-- cartorio  -->
        <li v-if="iptuDescription.cartorio">
          <Typography
            variant="title-large-secondary"
            class="title-iptu-dados"
            color="neutral-grey-5"
            tag="p"
          >
            <a
              :href="googleMapsLink(iptuDescription.cartorio)"
              class="cartorio-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ iptuDescription.cartorio?.match(/^\d+/)?.[0] }} °
            </a>
          </Typography>
          <Typography
            variant="helper"
            color="neutral-grey-5"
            tag="span"
          >
            Cartório
          </Typography>
        </li>
        <li
          v-if="iptuDescription.cartorio"
          class="divider"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import Typography from '@/components/base/Typography';
import { mapState } from 'pinia';

export default {
  components: {
    Typography,
  },
  props: {
    property: {
      type: Object,
      required: true,
    },
    iptuDescription: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatarMilhar(valor) {
      if (!valor) return;
      return Number(valor).toLocaleString('pt-BR');
    },
     googleMapsLink(endereco) {
      if (!endereco) return ;
      const query = encodeURIComponent(endereco);
      return `https://www.google.com/maps/search/?api=1&query=${query}`;
    },
    isTypologyHouse() {
      const propertyType = this.$store.getters["property/propertyType"] || 1;
      return propertyType === 2;
    }
  },
};
</script>

<style scoped lang="scss">
.iptu {
  @apply w-full py-0 ml-3 pr-6 mt-6 mb-6;

  &-title {
    @apply font-black;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-size: 22.5px;
    line-height: 22px;
    color: #3366CC;
    margin-bottom: 8px;
  }

  .typology {
    @apply flex text-center mt-6 bg-transparent;
    color: #707070;

    ul {
      display: flex;
      justify-content: space-between;
      padding-left: 0px;
      height: 60px;

      li {
        font-weight: normal;
        font-size: .7em;
        text-wrap: nowrap;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        height: 60px;
      }
    }
  }
}
.title-iptu-dados {
  font-size: 16px !important;
}
#report-button {
  color: white;
}
.divider {
  margin: 0 1em;
  height: 60px;
  border-right: 1px solid #707070;

  &.first {
    margin-left: 0;
  }
}
.cartorio-link {
  text-decoration: none;
  color: inherit;
}
</style>
