const initialState = {
  resultList: null,
  seen: [],
  personData: null,
};

const getters = {
  resultList: state => state.resultList,
  seen: state => state.seen,
  personData: state => state.personData,
};

const mutations = {
  SET_RESULTLIST (state, resultList) {
    state.resultList = resultList;
  },
  SET_SEEN (state, seen) {
    state.seen = seen;
  },
  SET_PERSON_DATA (state, personData) {
    state.personData = personData;
  },
};

const actions = {
  setResultList ({ commit }, resultList) {
    commit('SET_RESULTLIST', resultList);
  },
  setSeen ({ commit }, seen) {
    commit('SET_SEEN', seen);
  },
  setPersonData ({ commit }, personData) {
    commit('SET_PERSON_DATA', personData);
  },
};

export default {
  namespaced: true,
  actions,
  state: initialState,
  getters,
  mutations,
};