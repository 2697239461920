<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { PROPERTY_STATUS } from '../../common/constants';
import PropertyTabs from '../../views/property/PropertyTabs.vue';
import SkeletonProperty from '../../components/skeleton/SkeletonProperty.vue';
import SkeletonResults from '../../components/skeleton/SkeletonResults.vue';
import PropertyAttributes from '../../views/property/PropertyAttributes.vue';
import PropertyAdditionalIptu from '../../views/property/PropertyAdditionalIptu.vue';
import PropertyTypology from '../../views/property/PropertyTypology.vue';
import SimilarProperties from '../../components/property/SimilarProperties.vue';
import PropertyOffers from '../../views/property/PropertyOffers.vue';
import viewProductGTM from '../../utils/viewProductGTM';
import ExpandablePanel from '../../components/accordion/ExpandablePanel.vue';
import PropertyOwners from '../../views/property/PropertyOwners.vue';
import NotFound from '../../components/Messages/NotFound.vue';
import Loading from '../../components/Messages/NotFound.vue';
import Skeleton from '../../components/base/Skeleton/Skeleton.vue';
import Typography from '../../components/base/Typography';
import Select from '../../components/base/Select';
import PropertyFlaggingDialog from '../../views/property/PropertyFlaggingDialog.vue';
import Activity from '../../core/activity';
import Modal from '../base/Modal/Modal.vue';
import GoogleTagManager from '../../components/google/GoogleTagManager.vue';
import Button from '../../components/base/Button';
import { formatCurrency } from '../../utils';

export default {
  name: 'PropertyDetail',
  components: {
    PropertyTabs,
    SkeletonProperty,
    SkeletonResults,
    PropertyAttributes,
    PropertyAdditionalIptu,
    PropertyTypology,
    PropertyOffers,
    SimilarProperties,
    ExpandablePanel,
    PropertyOwners,
    NotFound,
    Loading,
    Skeleton,
    Typography,    
    Select,
    PropertyFlaggingDialog,
    Modal,
    GoogleTagManager,
    Button,
  },
  data () {
    return {
      PROPERTY_STATUS,
      isLoading: false,
      similars: null,
      offers: null,
      panel: {
        owners: {
          open: false,
        },
        transactions: {
          open: false,
        },
      },
      owners: null,
      transactionsList: [],
      transactionLoading: false,
      selectedYear: null,
      flagProperty: null,
      flagReason: 1,
      transactionUnit: [],
      transactionsModal: false,
      gtmEvent: null,
      hideCloseButton: false,
      inCondominiumGet: false,
      formatCurrency,
    };
  },  
  computed: {
    ...mapState('property', ['property']),
    ...mapGetters('map', ['currentCity']),
    ...mapGetters('property', ['mobileOwnersLoading']),
    ...mapGetters('property', [
      'mobileOwnersLoading',
      'transactions',
    ]),
    propertyStatus () {
      const status = Object.values(PROPERTY_STATUS).find(
        ({ value }) => value === this.property.phaseId,
      );

      return status && status.label;
    },
    isSafari () {
      return this.$browserDetect.isSafari;
    },
    isLead () {
      return this.$route.path.includes('/lead') || this.$route.path.startsWith('/product');
    },
    isSP () { 
      let currentCity = this.$store.getters['map/currentCity'];
      
      if (currentCity === null)  {
        currentCity = 'SP';
        
        return currentCity === 'SP';
      }
      
      return currentCity === 'SP';
    },
    ownersIsLoading () {
      return this.mobileOwnersLoading;
    },
    address () {
      return [
        this.property.addressLine1,
        this.property.addressLine2,
        this.property.addressLine3,
      ].join(', ');
    },
    transactions () {
      const transactionsList = this.$store.getters['property/transactions'];

      let finalTransactions = {};
      
      if (transactionsList) {
        const groupedByUnits = transactionsList.reduce((acc, transaction) => {
          const unitKey = transaction.units;

          if (!acc[unitKey]) {
            acc[unitKey] = [];
          }

          acc[unitKey].push({
            ...transaction,
            year: new Date(transaction.date).getFullYear(),
          });

          return acc;
        }, {});

       
        finalTransactions = transactionsList.reduce((acc, transaction) => {
          const unitKey = transaction?.units;
          const year = new Date(transaction.date).getFullYear();
    
          const groupUnits = groupedByUnits[unitKey]
            .filter(t => t.year !== year) 
            .map(t => ({
              ...t,
              year: t.year,
              date: new Date(t.date),
            }));
    
          if (!acc[year]) {
            acc[year] = [];
          }

          acc[year].push({
            ...transaction,
            groupUnits: [
              ...groupUnits,
              {
                ...transaction,
                date: new Date(transaction.date),
                year: year,
              },
            ].sort((a, b) => b.year - a.year),
          });

          return acc;
        }, {});
      }      

      return finalTransactions;
    },
  },
  watch: {
    async property (newVal, oldVal) {
      if (newVal === oldVal) return;

      this.transactionLoading = true;
      this.selectedYear = null;

      try {
        await this.Owners();
        await this.Offers();
        await this.Similars();
        await this.Transactions();
      } catch (error) {        
        console.error(error);
      } finally {
        this.transactionLoading = false;
      }
    },
    transactions (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.selectedYear = null;        
      }
    },
    '$store.state.property.transactions' (newTransactions) {
      this.transactionsList = newTransactions;

      if (this.transactionsList.length > 0) {
        this.transactionLoading = false;
      }
    },
    transactionsList (newTransactions) {
      if (newTransactions) {
        this.transactionLoading = false;
      }
    },
  },
  created () {
    if (this.$route.name !== 'condominiumDetails') {
      this.hideCloseButton = true;      
    }

    if (this.$route.name === 'condominiumDetails') {
      this.inCondominiumGet = true;
    }
  },
  methods: {
    ...mapActions({
      clearProperty: 'property/clearProperty',
      fetchProperty: 'property/fetchProperty',
      fetchOwners: 'property/fetchOwners',
      fetchOffers: 'property/fetchOffers',
      fetchTransactions: 'property/fetchTransactions',
      getRelatedCondos: 'property/getRelatedCondos',
    }),
    async Similars () {
      this.similars = { isLoading: true };

      if (!this.property || !this.property.condoId) return;

      this.similars = await this.getRelatedCondos({
        condoId: this.property.condoId,
        domain: this.$route.name === 'MobileCondominium' ? 'buyer' : this.$route.name,
      });

    },
    async Offers () {
      this.offers = null;

      if (this.property.phaseId !== 1) return;

      this.offers = { isLoading: true };
      this.offers = await this.fetchOffers({
        addressId: this.property.addressId,
        context: this.$route.name,
        adsIds: this.property.adsIds,
      });
      
      this.$forceUpdate();
    },
    async setProperty (item) {

      
      if (!(item && item.addressId)) return;
      
      if (!this.property || item.addressId !== this.property.addressId) {
        this.gtmEvent = viewProductGTM(item);
      }

      await this.fetchProperty({
        addressId: item.addressId,
        domain: this.$route.name,
      });

    },
    changePanelVisibility (panelName, button) {
      switch (button) {
      case true:
        for (const panelKey of Object.keys(this.panel)) {
          this.panel[panelKey].open = false;
        }

        this.panel[panelName].open = true;
        break;

      default:
        this.panel[panelName].open = !this.panel[panelName].open;
        break;
      }
    },
    async Owners () {
      this.owners = { isLoading: true };

      if (!this.isLead || !this.property || !this.property.addressId) return;

      this.owners = await this.fetchOwners({
        addressId: this.property.addressId,
        domain: this.$route.name,
      });
    },
    handleYearChange () {
      return this.selectedYear;
    },
    getYears (transactions) {
      return Object.keys(transactions).map(year => parseInt(year)).sort((a, b) => b - a);
    },
    biggestYear (transactions) {
      const years = this.getYears(transactions);

      return years.length ? years[0] : null;
    },
    totalItems (transactions) {
      const year = this.selectedYear || this.biggestYear(transactions);
      const count = transactions[year] ? transactions[year].length : 0;
      
      return `${count} ${
        count === 1 ? 'resultado encontrado' : count > 1 ? 'resultados encontrados' : 'Nenhum resultado encontrado'
      }`;
    },
    calculateFinancing (transaction) {
      return transaction.amountFinanced && transaction.typeFinancing
        ? `Sim | ${((parseInt(transaction.amountFinanced) / transaction.referenceValue) * 100).toFixed(2)} %`
        : 'Não';
    },

    sqlFormatter (sql) {
      const numericalPart = sql.split('-')[0];
      const verifyingDigit = sql.split('-')[1];
      const setor = numericalPart.substring(0, 3);
      const quadra = numericalPart.substring(3, 6);
      const lote = numericalPart.substring(6, 10);

      return `${setor}.${quadra}.${lote}-${verifyingDigit}`;
    },
    flag (reason = 1) {
      // reason: 1 = property, 2 = typology
      this.flagReason = reason;
      this.flagProperty = this.property;
    },
    sendEvent (event) {
      const events = {
        'btn-owners': {
          event: 'select_content',
          content_type: 'botao',
          creative_slot: 'empreendimento',
          creative_name: 'proprietários',
          level_name: 'lead',
        },
        'btn-transactions': {
          event: 'select_content',
          content_type: 'botao',
          creative_slot: 'empreendimento',
          creative_name: 'transacionados',
          level_name: 'lead',
        },
        'btn-more': {
          event: 'select_content',
          content_type: 'botao',
          creative_slot: 'transacionados',
          creative_name: 'ver_mais',
          level_name: 'lead',
        },
      };

      Activity.send(events[event]);
    },
    showTransactionsModal (unit) {
      this.transactionUnit = unit;
      this.transactionsModal = true;
    },
    handleShowMoreTransactions (transaction) {
      this.showTransactionsModal(transaction);
      this.sendEvent('btn-more');
    },
    hideTransactionsModal () {
      this.transactionsModal = false;
      this.transactionUnit = [];
    },
    async Transactions () {
      if (!this.property || !this.property.condoId) return;
      
      await this.fetchTransactions({
        condominium: this.property.condoId,
        domain: this.$route.name,
      });
    },
  },
};
</script>

<template>
  <div
    v-if="!$route.path.includes('login') && !$route.path.includes('mobile')"
    id="property-wrapper"
    :class="inCondominiumGet ? 'property-wrapper-all-screen' : 'property-wrapper'"
  >
    <Modal
      v-if="transactionsModal"
      :transactions-modal="true"
      :close-on-escape="false"
    >
      <div
        v-if="transactionsModal"
        class="transactions-modal"
      >
        <div class="content scrollbar">
          <div
            class="close-button"
            @click="hideTransactionsModal()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              class="mt-4"
            >
              <path
                d="M6.4 15L10 11.4L13.6 15L15 13.6L11.4 10L15 6.4L13.6 5L10 8.6L6.4 5L5 6.4L8.6 10L5 13.6L6.4 15ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z"
                fill="#1B2644"
              />
            </svg>
          </div>
          <div class="header-transactions flex flex-col">
            <div class="text-center">
              <Typography
                color="brand-dark"
                variant="title-medium"
                tag="span"
              >
                {{ transactionUnit[0].units }}
              </Typography>
            </div>
          </div>
          <div class="previous-transactions">
            <div class="flex align-center justify-center">
              <Typography
                color="brand-dark"
                variant="title-medium"
                tag="span"
              >
                Todas transações
              </Typography>
            </div>
            
            <div
              v-for="(unit, unitIndex) in transactionUnit"
              :key="unitIndex"
              class="border-t border-gray-300 border-solid flex flex-col gap-2 justify-start"
            >
              <div class="flex flex-row justify-between pt-2 gap-3">
                <div class="flex flex-col gap-2 justify-start width-limit">
                  <div class="flex flex-col gap 2 justify-start">
                    <Typography
                      color="neutral-grey-5"
                      variant="body-medium"
                      tag="div"
                    >
                      Matrícula: {{ unit.registryNumber }}
                    </Typography>
                    <Typography
                      color="neutral-grey-5"
                      variant="body-medium"
                      tag="div"
                    >
                      Contribuinte: {{ sqlFormatter(unit.sql) }}
                    </Typography>
                    <Typography
                      color="brand-dark"
                      variant="body-medium"
                      tag="span"
                    >
                      Tipo de transação:
                      <Typography
                        variant="label-medium"
                        color="highlight-dark"
                        tag="span"
                      >
                        {{ unit.type ? unit.type.split('.')[1] : '-' }}
                      </Typography>
                    </Typography>
                    <Typography
                      color="brand-dark"
                      variant="body-medium"
                      tag="span"
                    >
                      Foi utilizado financiamento: 
                      <Typography
                        variant="label-medium"
                        color="highlight-dark"
                        tag="span"
                      >
                        {{ unit.amountFinanced && unit.typeFinancing ? 
                          `Sim | ${((parseInt(unit.amountFinanced) / unit.referenceValue) * 100).toFixed(2)} %`
                          : 'Não' 
                        }}
                      </Typography>
                    </Typography>
                  </div>                  
                </div>
                
                <div class="flex gap-2 pb-2">
                  <div class="flex flex-col gap-2 justify-start width-limit">
                    <Typography
                      color="neutral-grey-5"
                      variant="title-medium"
                      tag="span"
                    >
                      Valor:
                      <Typography
                        color="brand-dark"
                        variant="title-medium"
                        tag="span"
                      >
                        {{ formatCurrency(unit.value) }}
                      </Typography>
                    </Typography>

                    
                    <Typography
                      color="neutral-grey-5"
                      variant="body-medium"
                      tag="span"
                    >
                      Valor venal: {{ formatCurrency(unit.referenceValue) }}
                    </Typography>
                    <Typography
                      color="neutral-grey-5"
                      variant="body-medium"
                      tag="div"
                    >
                      Base cálculo: {{ formatCurrency(unit.calcBase) }}
                    </Typography>
                  </div>
                </div>

                <div class="flex gap-2 pb-2">
                  <div class="flex flex-col gap-2 justify-start width-limit">
                    <Typography
                      color="neutral-grey-5"
                      variant="body-medium"
                      tag="div"
                      style="margin-top: 3px"
                    >
                      Data: {{ Intl.DateTimeFormat('pt-BR', {
                        timeZone: 'Etc/UTC'
                      }).format(unit.date) }}
                    </Typography>
                    
                    <Typography
                      color="neutral-grey-5"
                      variant="body-medium"
                      tag="span"
                    >
                      Tipo: {{ unit.propertyType }}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    
    <div    
      id="property"
      ref="propertyView"
      class="flex flex-col py-2 px-5 h-full"
    >
      <div v-if="!property">
        <skeleton-property />
      </div>
      <div v-else>
        <div
          id="basic-info"
          class="ml-3 mb-5"
        >
          <v-btn
            v-if="hideCloseButton"
            class="fixed dismiss-btn float-right"
            icon
            @click="clearProperty"
          >            
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <div class="flex align-center justify-between">
            <div class="flex align-center">
              <h1
                class="font-extrabold text-2xl"
                @click="handleEaster(property, $event)"
              >
                {{ !property.condoName === 'Casa' ? 'Condomínio:' : '' }} {{ property.condoName }}
              </h1>
              <v-chip
                class="ma-2 chipsCard"
                :class="'chipsCard' + property.phaseId"
              >
                {{ propertyStatus }}
              </v-chip>
            </div>
            <div>
              <v-btn
                text
                class="btnReport mr-16"
                @click="flag(1)"
              >
                Reportar erro do condomínio
              </v-btn>
            </div>
          </div>
          <span
            style="font-size: 14px;line-height: 1.43;"
            :class="['w-full', 'text-lg', 'text-gray-600', isSafari && 'mt-10']"
          >
            <div class="my-2 mt-1">
              <p
                v-if="property.addressLine1"
                class="my-0"
              >{{ property.addressLine1 }}</p>
              <p
                v-if="property.addressLine2"
                class="my-0"
              >{{ property.addressLine2 }}</p>
              <p
                v-if="property.addressLine3"
                class="my-0"
              >{{ property.addressLine3 }}</p>
            </div>
          </span>
          <div v-if="property.seller">
            <span class="font-weight-black">{{ property.seller.name }}</span>
            |
            <span class="text-sm">
              Lançamento: {{ propery.seller.date }} - Previsão de entrega:
              {{ property.seller.dueDate }}
            </span>
          </div>
        </div>
        <PropertyTabs
          :property="property"
          class="mt-8 max-w-carousel"
        />
        <div
          v-if="isLead"
          class="flex flex-row w-full justify-center my-4"
        >
          <div class="flex flex-row justify-center gap-2">
            <Button
              tag="a"
              variant="primary"
              href="#owners"
              @click="() => {
                sendEvent('btn-owners')
                changePanelVisibility('owners', true)
              }"
            >
              Proprietários
            </Button>
            <Button
              tag="a"
              variant="primary"
              href="#transactions"
              @click="() => {
                sendEvent('btn-transactions')
                changePanelVisibility('transactions', true)
              }"
            >
              Transacionados
            </Button>
          </div>
        </div>
        <PropertyAttributes
          class="ml-3 pr-6"
          :property="property"
        />
        <hr
          v-if="property.amenitiesCondominium"
          class="light-divider pr-6 py-0 px-0 ml-3"
        >
        <PropertyAdditionalIptu
          v-if="
            property &&
              property.iptuDescription &&
              (propertyStatus === 'Lançamento' || propertyStatus === 'Construção' ? false : true)
          "
          :property="property"
          :iptu-description="property.iptuDescription"
        />
        <hr
          v-if="property.iptuDescription"
          class="light-divider pr-6 py-0 px-0 ml-3"
        >
        <PropertyTypology
          class="ml-3 my-8 pr-6"
          :property="property"
          :property-status="propertyStatus || 'undefined'"
          @flag="flag(2)"
        />
        <div
          v-if="property.typology"
          class="light-divider"
        />

        <div
          v-if="similars && similars.length"
          class="light-divider"
        />
        <template v-if="offers && offers.isLoading">
          <SkeletonResults />
        </template>
        <template v-else>
          <PropertyOffers
            v-if="offers && offers.length"
            class="ml-3 pr-6"
            :property="property"
            :offers="offers"
          />
          <hr
            v-if="offers && offers.length"
            class="light-divider pr-6 pb-4 pt-0 px-0 ml-3 mt-4"
          >
        </template>        
        <SimilarProperties
          :items="similars"
          class="mx-3"
          @click:item="setProperty"
        />
        <ExpandablePanel
          v-if="isLead && isSP"
          id="owners"
          title="Proprietários"
          class="my-4 mx-2 pr-4"
          :open="panel.owners.open"
          :is-desktop="true"
          tracker="proprietários_accordion"
          @panel-expand="() => changePanelVisibility('owners', false)"
        >
          <template v-if="ownersIsLoading">
            <Loading />
          </template>
          <template v-else>
            <template v-if="owners?.length">
              <PropertyOwners
                v-if="isLead"
                class="ml-3 pr-6"
                :owners="owners"
                :condo-name="property.condoName"
                :address="address"
              />
            </template>
            <template v-else>
              <NotFound />
            </template>
          </template>
        </ExpandablePanel>
        <ExpandablePanel
          v-if="isLead && isSP"
          id="transactions"
          title="Transacionados"
          class="my-4 mx-2 pr-4"
          :open="panel.transactions.open"
          :is-desktop="true"
          tracker="transacionados_accordion"
          @panel-expand="() => changePanelVisibility('transactions', false)"
        >
          <template v-if="transactionLoading">
            <Skeleton
              width="99%"
              :height="148"
              :radius="5"
              class="mt-2"
            />
          </template>          
          <template v-else>
            <template v-if="Object.keys(transactions).length">
              <section class="flex flex-row items-end justify-between gap-2 mb-1 mt-1">
                <Select
                  v-if="Object.keys(transactions).length"
                  v-model="selectedYear"
                  name="transactionsSelect"
                  :label="selectedYear ? 'Filtrando pelo ano' : biggestYear(transactions)"
                  placeholder="Selecione o ano para filtrar."
                  :items="getYears(transactions)"
                  :variant="'default'"
                  class="select-menu"
                  @input="() => selectedYear"
                />
                <Typography
                  color="brand-dark"
                  variant="title-medium"
                  tag="p"
                >
                  {{ totalItems(transactions) }}
                </Typography>
              </section>
              <div 
                v-for="(transactionsByYear, year) in transactions" 
                :key="year"
                :class="{
                  'border-b border-gray-300 border-solid flex flex-col gap-2 justify-start': true,
                  'invisible-lines': !(typeof selectedYear === 'object' && biggestYear(transactions) === year || (typeof selectedYear === 'number' && year === selectedYear))
                }"
              >
                <template v-if="selectedYear ? selectedYear === Number(year) : Number(year) === biggestYear(transactions)">
                  <div
                    v-for="(transaction, transactionId) in transactionsByYear"
                    :key="transactionId"
                    class="border-b border-gray-300 border-solid flex flex-col gap-2 justify-start"
                  >
                    <div class="flex flex-row justify-start pt-2">
                      <div class="flex flex-col w-1/3 gap-2">
                        <Typography
                          color="neutral-grey-5"
                          variant="title-medium"
                          tag="span"
                        >
                          Unidade:
                          <Typography
                            color="brand-dark"
                            variant="title-medium"
                            tag="span"
                          >
                            {{ transaction.units }}
                          </Typography>
                        </Typography>
                        <Typography
                          color="brand-dark"
                          variant="body-medium"
                          tag="div"
                        >
                          Matrícula: {{ transaction.registryNumber }}
                        </Typography>
                        <Typography
                          color="brand-dark"
                          variant="body-medium"
                          tag="div"
                        >
                          Contribuinte: {{ sqlFormatter(transaction.sql) }}
                        </Typography>
                        <Typography
                          color="brand-dark"
                          variant="body-medium"
                          tag="span"
                        >
                          Tipo de transação:
                          <Typography
                            variant="label-medium"
                            color="highlight-dark"
                            tag="span"
                          >
                            {{ transaction.type ? transaction.type.split('.')[1] : '-' }}
                          </Typography>
                        </Typography>
                      </div>

                      <div class="flex flex-col w-1/3 gap-2 justify-start">
                        <Typography
                          color="neutral-grey-5"
                          variant="title-medium"
                          tag="span"
                        >
                          Valor:
                          <Typography
                            color="brand-dark"
                            variant="title-medium"
                            tag="span"
                          >
                            {{ formatCurrency(transaction.value) }}
                          </Typography>
                        </Typography>
                        <Typography
                          color="brand-dark"
                          variant="body-medium"
                          tag="div"
                        >
                          Valor venal: {{ formatCurrency(transaction.referenceValue) }}
                        </Typography>
                        <Typography
                          color="brand-dark"
                          variant="body-medium"
                          tag="div"
                        >
                          Base cálculo: {{ formatCurrency(transaction.calcBase) }}
                        </Typography>
                        <Typography
                          color="brand-dark"
                          variant="body-medium"
                          tag="span"
                        >
                          Foi utilizado financiamento:
                          <Typography
                            variant="label-medium"
                            color="highlight-dark"
                            tag="span"
                          >
                            {{ calculateFinancing(transaction) }}
                          </Typography>
                        </Typography>
                      </div>

                      <div class="flex flex-col w-1/3 gap-2 justify-start">
                        <Typography
                          color="brand-dark"
                          variant="body-medium"
                          tag="div"
                        >
                          Data: {{ Intl.DateTimeFormat('pt-BR', { timeZone: 'Etc/UTC' }).format(new Date(transaction.date)) }}
                        </Typography>
                        <Typography
                          color="brand-dark"
                          variant="body-medium"
                          tag="div"
                        >
                          Tipo: {{ transaction.propertyType }}
                        </Typography>

                        <div class="width-button">
                          <Button
                            v-if="transaction.groupUnits.length > 1"
                            variant="primaryXs"
                            @click="handleShowMoreTransactions(transaction.groupUnits)"
                          >
                            Mais transações
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div class="flex w-full gap-2 justify start pb-2" />
                  </div>
                </template>
              </div>
            </template>
            <template v-else>
              <NotFound />
            </template>
          </template>
        </ExpandablePanel>

        <div class="w-full text-center">
          <PropertyFlaggingDialog
            v-if="!!flagProperty"
            :property="flagProperty"
            :reason="flagReason"
            @update:visibility="flagProperty = null"
          />
        </div>
      </div>
    </div>
    <GoogleTagManager :track-event="gtmEvent" />
  </div>
</template>

<style scoped lang="scss">
.width-button {
  width: 119px;
}
.transactions-modal {
  position: sticky;
  top: 0;
  left: 0;

  width: clamp(100%, 100%, 650px);
  height: 100dvh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 1999;

  .content {
    width: 650px;
    max-height: 615px;
    background-color: #fff;
    margin: auto 0;
    border-radius: 20px;

    .close-button {
      position: absolute;
      right: 24px;
      cursor: pointer;
    }
    .header-transactions {
      background-color: #F4F4F4;
      padding: 16px 24px;
      border-radius: 20px 20px 0 0;
    }
    .previous-transactions {
      display: flex;
      flex-direction: column;
      padding: 0px 24px 24px 24px;
      border-bottom: none;
      gap: 8px;
    }
  }
}

.btnReport {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.25;
  color: #3366cc;
  text-decoration: underline;
  text-transform: inherit;
  border-radius: 22px;
  padding: 0px 31px;
}

.max-w-carousel {
  max-width: 97%;
  margin: 0 auto;
  margin-top: 2rem;
  width: 100%;
}

.property-wrapper {
  width: 100%;
  max-width: 780px;
  height: calc(100vh - 75px);
  position: absolute;
  top: 75px;
  right: 0;
  left: 0;
  z-index: 15;
  margin: 0 auto;

  @media only screen and (min-width: 1600px) {
    max-width: 960px;
  }
}

.property-wrapper-all-screen {
  width: 100%;
  height: calc(100vh - 75px);
  margin: 0 auto;

  @media only screen and (min-width: 1600px) {
    max-width: 960px;
  }
}

#property {
  overflow-y: auto;
  background-color: rgba(255, 255, 255, 1);

  .chipsCard {
    margin: 0px 0px 0px 15px !important;
    font-size: 11px;
    font-weight: bold;
    overflow: inherit;
  }

  .chipsCard1 {
    background: #eb6964;
    color: #fff;
  }

  .chipsCard4 {
    background: #7adc56;
    color: #fff;
  }

  .chipsCard6 {
    background: #f6ca6b;
  }
}

#blueprints {
  img {
    height: 320px;
    width: 100%;
  }
}

.dismiss-btn {
  min-width: 36px !important;
  position: fixed;
  right: 42px;
  top: 90px;
  background-color: #fff;
  z-index: 1;
  box-shadow: 0px 0px 3px -1px #000;
}

.report-btn {
  background-color: $primary-blue !important;
  color: white;
}

.property__status {
  padding: 4px 8px;
  background-color: $primary-blue;
  border: 1px solid white;
  color: white;
}

.light-divider {
  border: 0;
  border-top: 1px solid #E8E8E8;
  width: 95%;
}

.select-menu {
  width: 35%;
}

.invisible-lines {
  border-bottom: none;
}

.scrollbar {
  overflow-y: auto;

  &::-webkit-scrollbar {
      background-color: transparent;
      width: 3px;
      height: 100%;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      width: 3px;
    }
}

.width-limit {
  max-width: 250px;
}

</style>
