<script setup>
import { computed, inject, ref, watch, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router/composables';

const vuexStore = inject('vuex-store');

const personData = ref({});
const loading = ref(false);
const seen = computed(() => vuexStore.getters['personData/seen']);
const router = useRouter();
const showing = ref(true);
const route = useRoute();

async function fetchPersonData (person) {

  try {
    const result = await base.post(
      'person-data/getByItem',
      {
        payLoad: person,
      },
      { context: 'person-data' },
    );

    personData.value = result.data.response;    
  } catch (error) {
    personData.value = undefined;
  }

  loading.value = false;

  if (!seen.value) {
    vuexStore.dispatch('personData/setSeen', [person?.SequentialId]);
  } else {
    if (!seen.value.includes(person.SequentialId)) {      
      vuexStore.dispatch('personData/setSeen', [
        ...seen.value,
            person?.SequentialId,
      ]);
    }
  }

  if (personData.value) {
    await vuexStore.dispatch('personData/setPersonData', personData.value);
    router.push('/mobile/person-data/detail');
    
    const mainContentDiv = window.document.querySelector('#main-content');
    
    setTimeout(() => {
      if (mainContentDiv.scrollTop > 0) {
        mainContentDiv.scrollTo({
          top: -1,
          behavior: 'smooth',
        });
      }
    }, 500);
  }

  return;
}

watch(route, (newRoute) => {
  if (newRoute.fullPath && newRoute.fullPath === '/mobile/person-data/detail' || newRoute.fullPath && newRoute.fullPath === '/mobile/person-data/list') {
    showing.value = false;
  } else {
    showing.value = true;
  }
});

onMounted(() => {
  showing.value = true;
});
</script>

<script>
import api from '../../api';
import base from '@/api/base';
import { mapActions, mapState, mapMutations } from 'vuex';
import Typography from '../base/Typography/Typography.vue';
import Button from '@/components/base/Button';
import SkeletonRecentQuery from '../skeleton/SkeletonRecentQuery.vue';

export default {
  name: 'LastQueries',
  components: {
    Typography,
    Button,    
    SkeletonRecentQuery,
  },
  data () {
    return {
      itemsPerPage: 5,
      lastQueries: [],
      enable: true,
      isMobile: false,
    };
  },
  computed: {
    ...mapState(
      'person', [
        'isLoading',
        'detail',
      ]),
  },
  async created () {

  },
  async mounted () {

    this.lastQueries = await this.fetchLastQueries();
    
    const routeActual = this.$route.path.includes('mobile');
    
    if (routeActual) {
      this.isMobile = true;
    }
  },
  methods: {
    ...mapMutations('person', [
      'SET_DETAIL_LOADING',
      'SET_DETAIL',
      'SET_HAS_FAC',
      'SET_SCORE',
      'SET_ERROR',
    ]),
    ...mapActions({      
      disableMap: 'core/disableMap',
      fetchConsultaDadosQuota: 'user/fetchConsultaDadosQuota',
    }),
    async fetchLastQueries () {
      const response = await api.recentSearch.getLastSearchs(this.itemsPerPage);
      
      return response;
    },
    async getQuotas () {
      let result = null;
      const quotas = await api.user.getConsultaDadosQuota();

      const usedQuotas = (quotas.total - quotas.available);

      if (usedQuotas === 0) {
        result = quotas.total;

        return result;
      }

      return usedQuotas;
    },
    async getDetail (item) {
      this.SET_DETAIL_LOADING(true);
      this.SET_DETAIL(null);
      this.SET_HAS_FAC(null);
      this.SET_SCORE(null);

      try {
        const response = await base.post(
          'person-data/getByItem',
          {
            payLoad: item,
          },
          { context: 'person-data' },
        );

        this.SET_DETAIL_LOADING(false);
        this.SET_DETAIL(response.data.response);
        
        this.$router.push('/person-data/detail');
      } catch {
        this.SET_DETAIL_LOADING(false);
      }
    },
    async loadAllItems () {
      this.enable = false;

      return this.lastQueries = await api.recentSearch.getLastSearchs(await this.getQuotas());
    },

    formatCpfCnpj (v) {
    
      if (v.length <= 11) {
        v = v.replace(/(\d{3})(\d)/, '$1.$2');
        v = v.replace(/(\d{3})(\d)/, '$1.$2');
        v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
      } else {
        v = v.replace(/^(\d{2})(\d)/, '$1.$2');
        v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
        v = v.replace(/\.(\d{3})(\d)/, '.$1/$2');
        v = v.replace(/(\d{4})(\d)/, '$1-$2');
      }
    
      return v;
    },
    
    formatData (date) {
      const data = new Date(date);

      return data.toLocaleDateString('pt-BR', { day: 'numeric', month: 'long', year: 'numeric' });
    },
  },
};
</script>
<template>
  <section
    v-if="lastQueries && lastQueries.length && showing"
    :class="isMobile ? 'person-data-last-queries mb-5' : 'last-queries'"
  >
    <Typography
      tag="h3"
    >
      Últimas consultas
    </Typography>
    <ul>
      <li
        v-for="query in lastQueries"
        :key="query.SequencialId"      
        @click="isMobile ? fetchPersonData(query) : getDetail(query)"
      >
        <Typography tag="h6">
          {{ formatData(query.date) }}
        </Typography>
        <Typography tag="p">
          {{ formatCpfCnpj(query.document) }} <span> {{ query.name }} </span>{{ query.age }} anos    
        </Typography>
        <Typography
          v-if="query.total > 1"
          tag="h6"
        >
          {{ query.total }} qtde
        </Typography>
      </li>
    </ul>
    <template v-if="getQuotas && enable">
      <Button
        :v-if="itemsPerPage < getQuotas && itemsPerPage"
        class="view-all-btn"
        @click="loadAllItems"
      >
        Ver todas as consultas
      </Button>
    </template>
    <template v-else>
      <Typography tag="p">
        Sem mais consultas
      </Typography>
    </template>
  </section>
  <SkeletonRecentQuery v-else-if="showing" />
</template>

<style lang="scss">
.last-queries {
  width: 100%;
  margin: 0 auto;  
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;  
}

.last-queries > h3 {  
  color: var(--colors-brand-dark);
  @include typography-title-large;
}

.last-queries > ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  padding-left: 0 !important;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 3px;
    height: 100%;    
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    width: 3px;
  }
}

.last-queries > ul > li {
  width: 100%;
  display: flex;
  gap: 5px;
  flex-direction: column;
  border-radius: none;
  border-bottom: solid 1px var(--colors-neutral-grey-3);
  cursor: pointer;
  padding-bottom: 10px;

  &:hover {
    background-color: var(--colors-neutral-grey-3);
  }
}

.last-queries  > ul > li > h6 {
  color: var(--colors-neutral-grey-6);
  @include typography-body-xs
}

.last-queries  > ul > li > p {
  color: var(--colors-brand-dark);
  @include typography-label-medium;
  text-transform: uppercase;
  margin-bottom: 0;
}

.last-queries  > ul > li > p > span {
  color: var(--colors-highlight-default);
  text-transform: uppercase;
}

.view-all-btn {
  margin: 0 auto;

  &:hover {
    background-color: var(--colors-brand-dark-hover);
  }
}

.person-data-last-queries {
  border: solid 1px var(--colors-neutral-grey-2);
  border-radius: 8px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px; 
  padding: 16px;
}

.person-data-last-queries:last-child {
  align-items: center;
}

.person-data-last-queries > h3 {  
  display: none;
}

.person-data-last-queries > ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  padding-left: 0 !important;  

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 3px;
    height: 100%;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    width: 3px;
  }
}

.person-data-last-queries > ul > li {
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;
  border-radius: none;
  border-bottom: solid 1px var(--colors-neutral-grey-5);
  cursor: pointer;
  padding-bottom: 10px;

  &:hover {
    background-color: transparent;
  }

  &:active {
    background-color: transparent;
  }
}

.person-data-last-queries > ul > li > h6 {
  color: var(--colors-neutral-grey-4);
  @include typography-body-xs
}

.person-data-last-queries > ul > li > p {
  color: var(--colors-neutral-grey-2);
  @include typography-label-medium;
  text-transform: uppercase;
  margin-bottom: 0;
}

.person-data-last-queries > ul > li > p > span {
  color: var(--colors-neutral-grey-2);
  text-transform: uppercase;
}
</style>