import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters('search', [
      'regionSelected',
    ]),
    locationValueData () {
      switch (this.filterMode) {
        case 'radius':
          return this.radiusAddress
        case 'region': 
          return this.regionSelected
      }
    },
    radiusAddress () {
      let address = {}
      if (!this.autocompleteData || !this.autocompleteData.address_components) return null; 
      this.autocompleteData.address_components.forEach(component => {
        component.types.forEach(type => {
          switch (type) {
          case 'sublocality_level_1':
            address.neighborhood = component.long_name;
            break;
          case 'route':
            address.street = component.long_name;
            break;
          case 'street_number':
            address.addressNumber = component.long_name;
            break;
          default:
            break;
          }
        });
      });

      if(address?.street){
        if(address?.addressNumber) {
          return `${address.street}, ${address.addressNumber}`
        }
        return address.street
      }

      return address.neighborhood
    },
    regionAddress () {
      return this.autocompleteData.address_components.find(component => {
        component.types.forEach(type => {
          if(type === 'route'){
            return component.long_name;
          }
        });
      });
    },
  }
}