var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[(_vm.typologies)?_c('div',{staticClass:"typologies"},[_vm._m(0),_vm._l((_vm.types),function(type,index){return _c('div',{key:index,staticClass:"more"},[_c('h2',{staticClass:"typologies-type",class:{ 'mt-4': index }},[_vm._v(" "+_vm._s(type)+" ")]),(_vm.typologiesByPropertyType[type] && _vm.typologiesByPropertyType[type].hasDefaults)?_c('div',_vm._l((_vm.typologiesByPropertyType[type].typologies),function(typology,index){return _c('div',{key:index},[(typology.default)?_c('DefaultTypologyCard',{staticClass:"my-4",attrs:{"typology":typology,"price-history":_vm.priceHistoryByAddress ? _vm.priceHistoryByAddress[typology.typology_id] : []}}):_vm._e()],1)}),0):_vm._e(),_c('div',{staticClass:"more-inner",class:{
          active:
            _vm.expandedTypologies[type] ||
            !_vm.typologiesByPropertyType[type] ||
            !_vm.typologiesByPropertyType[type].hasDefaults,
        }},[(_vm.typologiesByPropertyType[type])?_c('more-typologies-table',{attrs:{"typologies-rows":_vm.typologiesByPropertyType[type].typologies.filter(el => el.default === 0),"price-history":_vm.priceHistoryByAddress}}):_vm._e()],1),(
          _vm.typologiesByPropertyType[type] &&
            _vm.typologiesByPropertyType[type].hasDefaults &&
            _vm.typologiesByPropertyType[type].typologies.filter(el => el.default === 0).length
        )?_c('v-btn',{staticClass:"more-toggle",attrs:{"depressed":"","outlined":"","rounded":"","color":"#3566C9"},on:{"click":function($event){return _vm.toggleTypologyByType(type)}}},[(!_vm.expandedTypologies[type])?_c('span',[_vm._v("Ver mais tipologias de "+_vm._s(type))]):_c('span',[_vm._v("Fechar")])]):_vm._e()],1)})],2):_c('div',{staticClass:"w-full flex flex-col justify-center"},[_c('h2',{staticClass:"typologies-type"},[_vm._v(" Tipologias e valores ")]),_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"width":"100%","type":"list-item-three-line"}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',{staticClass:"typologies-title"},[_vm._v(" Tipologias tipo e valores ")]),_c('p',{staticClass:"typologies-subtitle"},[_vm._v(" Tipologias mais presentes entre os anúncios coletados ")])])
}]

export { render, staticRenderFns }