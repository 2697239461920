export default async function applySellerSummary({ commit, rootState }) {
  const flagged = rootState.flag.flagged;
  const { originalResult } = rootState[rootState.route.name];

  const findRegion = region => originalResult.find(el => el.type === region)

  const regions = {
    street: findRegion('street'),
    radius: findRegion('radius'),
    district: findRegion('region'),
  }

  const regionLabel = {
    street: 'Rua',
    radius: 'Raio 500m',
  }

  const removeFlagged = (region = []) => {
    const removeFlaggedFilter = coord => !flagged.includes(coord[0])
    return flagged ? region.filter(removeFlaggedFilter) : region;
  }

  const valueMapper = {
    transaction: 6,
    offer: 7,
    evaluation: 8
  }

  const averageValueCalculator = (region, valueType) => {
    const position = valueMapper[valueType];
    const coordMap = coord => coord[position];
    const valueFilter = val => val !== null;

    const value = region.map(coordMap).filter(valueFilter)
    const averageValue = value.length ? value.reduce((a, b) => a + b) / value.length : null

    return averageValue ? Math.round(averageValue) : null
  }

  const summary = {}

  for (const regionKey in regions) {
    const region = regions[regionKey]?.region || regionLabel[regions[regionKey]?.type]
    const coords = regions[regionKey]?.results.coords

    summary[regionKey] = {
      limit: region,
      condominiumCount: removeFlagged(coords).length,
      avgTransactionValue: averageValueCalculator(removeFlagged(coords), 'transaction'),
      avgOfferValue: averageValueCalculator(removeFlagged(coords), 'offer'),
      avgEvaluationValue: averageValueCalculator(removeFlagged(coords), 'evaluation'),
    }
  }

  commit('SET_SEARCH_SUMMARY', summary);
}
