<template>
  <div class="calculadora">
    <iframe
      class="iframe"
      :src="workspacePowerBiCalculator"
      frameborder="0"
    />
  </div>
</template>

<script>

export default {
  name: 'Calculator',
  data () {
    return {
      workspacePowerBiCalculator: process.env.VUE_APP_WORKSPACE_POWER_BI_CALCULATOR,
    };
  },
};
</script>

<style scoped lang="scss">
  .calculadora {
    width: 100%;
    height: calc(100% - 75px);
    background-color: #fff;
    position: absolute;
    top: 75px;
    z-index: 4;
    .iframe {
      padding-top: 16px;
      width: 100%;
      height: 100%;
    }
  }
</style>
