import api from '@/api';
import { MAP_OPTIONS } from '@/common/constants';

export default async function sellerSearch({ commit, state, rootState, dispatch }, filter) {
  try {
    commit('SET_ERROR', null);
    commit('SET_LOADING', true);

    const routeName = rootState.route.name

    const { attributesFilter = {} } = filter;
    const { filterMode } = state;

    const { address } = filter;

    const requestParams = {
      filterMode,
      radius: MAP_OPTIONS.DEFAULT_RADIUS,
      coordinates: address.info.geolocation,
      routeName,
      addressId: address.info.addressId,
      condoId: address.info.condoId,
      selectedAttributes: address.info.selectedAttributes,
      yearOfConstruction: address.info.yearOfConstruction,
    }

    const response = await api.search.searchSeller(requestParams, attributesFilter, routeName);

    const concatChunks = response 
      ?  response.flatMap(chunk => {
          const containsCoords = chunk && chunk.results && chunk.results.coords;
          return containsCoords ? chunk.results.coords : [];
        })
      : []

    const sortedResponse = {
      response: concatChunks.sort((a, b) => (a[9] > b[9] ? 1 : -1)),
    };

    const { response: results = [] } = sortedResponse || {};

    commit('SET_ORIGINAL_SEARCH_RESULT', response);
    commit('SET_LAST_SEARCH_PARAMETERS', { requestParams, attributesFilter });
    commit('setMapSearchResult', results);
    commit('property/SET_PROPERTY', null, { root: true });
    commit('FETCH_ADDRESSESS_SUCCESS');
    dispatch('fetchSellerAddresses', routeName);
  } catch (error) {
    console.error(error);
    commit('FETCH_ADDRESSESS_ERROR', error);
  } finally {
    commit('SET_LOADING', false);
  }
}
