<script>
import { mapState } from 'vuex';
import api from '../../api';
import PropertyDetail from '../../components/property/propertyDetail.vue';
import { SET_PROPERTY } from '../../store/modules/mutationTypes';
import NotFound from '../../components/Messages/NotFound.vue';
import Button from '../../components/base/Button';


export default {
  name: 'CondominiumDetails',
  components: {
    PropertyDetail,
    NotFound,
    Button,
  },
  data () {
    return {
      noData: false,      
    };
  },
  computed: {
    ...mapState('property', ['property']),
  },

  async created () {
    try {     
      const condoId = this.$route.params.condoId;
      const routeName = this.$route.name;

      const dataCondominiumByCondoId = await api.locations.getCondominium(condoId);
      
      const data = await api.address.getAddress(dataCondominiumByCondoId.response.addressId, routeName);
  
      this.$store.commit(`property/${SET_PROPERTY}`, data.response[0]);
      
    } catch (error) {
      this.noData = true;
      console.error('Erro ao buscar detalhes do prédio:', error);
    }
  },
};
</script>

<template>
  <section v-if="noData">
    <NotFound />
    <Button
      tag="a"
      variant="primary"
      href="/"
      class="back-to-home-btn"
    >
      Voltar para home
    </Button>
  </section>
  <section v-else>
    <property-detail />
  </section>
</template>

<style scoped>
.back-to-home-btn {
  max-width: 25rem;
  margin: 20px auto;
}
</style>