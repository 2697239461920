<template>
  <li
    v-if="property && property.addressId"
    class="property"
  >
    <a
      class="flex px-2 h-full w-full"
      :class="isFlagged === false ? 'cardDisable' : ''"
      v-on="isFlagged === true ? inputListeners : ''"
      @click="emitCondoId"
    >
      <v-card class="w-full card">
        <div>
          <div class="h-32">
            <v-chip
              v-if="propertyStatus"
              class="ma-2 chipsCard"
              :class="'chipsCard' + property.phaseId"
            >
              {{ propertyStatus }}
            </v-chip>
            <v-img
              v-if="property"
              :id="property.addressId"
              :src="fotoCard"
              aspect-ratio="5px 0 0 5px"
              :lazy-src="default404"
              class="w-full h-32 min-h-full"
              :gradient="
                isFlagged === false
                  ? 'to top right, rgba(0,0,0,.50), rgba(0,0,0,.50)'
                  : ''
              "
              @error="setLoadingError()"
            />
          </div>
          <div class="containerInfFlagged">
            <div class="containerInfos">
              <div class="flex align-baseline">
                <v-card-title class="titulo">
                  <span class="titleDiv">{{ property.condo }}</span>
                </v-card-title>
                <div
                  v-if="allowHighlighting"
                  class="viewInMap"
                  @click="setHighlightedItemLocal(property)"
                >
                  <img
                    v-if="isFlagged"
                    width="8px"
                    src="@/assets/images/icon-pin-2.svg"
                  >
                  <img
                    v-if="!isFlagged"
                    width="8px"
                    src="@/assets/images/icon-pin-disabled.svg"
                  >
                  <span class="ml-1">Ver no mapa</span>
                </div>
              </div>
              <v-card-text
                v-if="property.addressLine1"
                class="text mb-0"
              >
                {{ property.addressLine1 }}
              </v-card-text>
              <v-card-text
                v-if="property.addressLine2"
                class="text"
              >
                {{ property.addressLine2 }}
              </v-card-text>
              <v-card-text
                v-if="property.yearOfConstruction"
                class="text construction-year"
              >
                Construção - {{ property.yearOfConstruction }}
              </v-card-text>
            </div>
          </div>
          <div class="containerValueADSList">
            <ul
              v-if="property.typology"
              class="attributesList"
            >
              <li v-if="property.typology.utilArea">
                <span class="flex flex-col items-center justify-center">
                  <span class="typology-data">
                    {{ utilArea }} m&sup2;
                  </span>
                  <span class="typology-suffix">
                    Área útil
                  </span>
                </span>
              </li>
              <div
                v-if="property.typology.bedRooms"
                class="divider typology-divider"
              />
              <li v-if="property.typology.bedRooms">
                <span class="flex flex-col items-center justify-center">
                  <span class="typology-data"> 
                    {{ typologyData(property.typology.bedRooms) }}
                  </span>
                  <span class="typology-suffix">
                    Dorm{{ plural(property.typology.bedRooms) }}.
                  </span>
                </span>
              </li>
              <div
                v-if="property.typology.suites"
                class="divider typology-divider"
              />
              <li v-if="property.typology.suites">
                <span class="flex flex-col items-center justify-center">
                  <span
                    v-if="property.typology.suites"
                    class="typology-data"
                  > 
                    {{ typologyData(property.typology.suites) }}
                  </span>
                  <span
                    v-if="!property.typology.suites"
                    class="typology-data"
                  >
                    N/I
                  </span>
                  <span class="typology-suffix">
                    Suíte{{ property.typology.suites ? plural(property.typology.suites) : '' }}
                  </span>
                </span>
              </li>
              <div
                v-if="property.typology.parkingLots"
                class="divider typology-divider"
              />
              <li v-if="property.typology.parkingLots">
                <span class="flex flex-col items-center justify-center">
                  <span
                    v-if="property.typology.parkingLots"
                    class="typology-data"
                  > 
                    {{ typologyData(property.typology.parkingLots) }}
                  </span>
                  <span
                    v-if="!property.typology.parkingLots"
                    class="typology-data"
                  >
                    N/I
                  </span>
                  <span class="typology-suffix">
                    Vaga{{ property.typology.parkingLots ? plural(property.typology.parkingLots) : '' }}
                  </span>
                </span>
              </li>
            </ul>
            <div
              v-if="showTypologies && property.typologiesFound"
              class="h-full"
            >
              <div>
                <v-card-text class="text pb-0">Tipologias encontradas:</v-card-text>
                <v-card-text class="anuncio">
                  <div
                    v-if="
                      property.typologiesFound.utilArea &&
                        property.typologiesFound.utilArea.length === 2 &&
                        property.typologiesFound.utilArea[0] !=
                        property.typologiesFound.utilArea[1]
                    "
                    class="mt-1"
                  >
                    {{
                      property.typologiesFound.utilArea
                        .map(n => $options.filters.thousand(n) + 'm²')
                        .join(' - ')
                    }}
                  </div>
                  <div
                    v-if="
                      property.typologiesFound.utilArea &&
                        property.typologiesFound.utilArea.length === 2 &&
                        property.typologiesFound.utilArea[0] ==
                        property.typologiesFound.utilArea[1]
                    "
                    class="mt-1"
                  >
                    {{
                      $options.filters.thousand(
                        property.typologiesFound.utilArea[0]
                      ) + 'm²'
                    }}
                  </div>

                  <div
                    v-if="
                      property.typologiesFound.value &&
                        property.typologiesFound.value[0] !=
                        property.typologiesFound.value[1]
                    "
                  >
                    {{
                      property.typologiesFound.value &&
                        property.typologiesFound.value
                          .map(n => $options.filters.money(n))
                          .join(' - ')
                    }}
                  </div>
                  <div
                    v-if="
                      property.typologiesFound.value &&
                        property.typologiesFound.value[0] ==
                        property.typologiesFound.value[1]
                    "
                  >
                    {{
                      $options.filters.money(
                        property.typologiesFound.value[0]
                      )
                    }}
                  </div>
                  <div
                    v-if="
                      property.characteristics &&
                        property.characteristics.length > 0
                    "
                  >
                    {{ property.characteristics.join(', ') }}
                  </div>
                  <div v-if="property.offersCount">
                    {{ property.offersCount }}
                    <span class="text-capitalize">{{ offerText }}</span>{{
                      !property.offersCount || property.offersCount > 1 ? 's' : ''
                    }}
                  </div>
                </v-card-text>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            v-if="showFavoriteButton"
            class="containerFlagged"
          >
            <v-checkbox
              v-if="!disableFlagging"
              v-model="isFlagged"
              class="mt-0 pt-0"
              @click="togglePropertyFlag(property.addressId)"
            >
              <template #label>
                <Typography
                  variant="body-xs"
                  tag="span"
                  color="brand-dark"
                  class="checkbox-label"
                >
                  Considerar no relatório
                </Typography>
              </template>
            </v-checkbox>
          </div>

          <v-card-text
            v-if="property.ads && property.phaseId === 1"
            class="anuncio mt-2"
          >
            {{ property.ads }}
            <span class="text-capitalize">{{ offerText }}</span>{{ !property.ads || property.ads > 1 ? 's' : '' }}
          </v-card-text>
        </div>
      </v-card>
    </a>
  </li>
</template>

<script>
/* eslint-disable  */
import { mapActions, mapState, mapMutations } from 'vuex';
import PropertyImage from '../property/PropertyImage';
import { PROPERTY_STATUS } from '../../common/constants';

import default404 from '@/assets/images/404.jpeg';
import Typography from '../base/Typography';

export default {
  components: {
    PropertyImage,
    Typography
},
  props: {
    showFavoriteButton: {
      type: Boolean,
      default: true,
    },
    showTypologies: {
      type: Boolean,
      default: true,
    },
    allowHighlighting: {
      type: Boolean,
      default: true,
    },
    offerText: {
      type: String,
      default: 'oferta',
    },
    property: {
      type: Object,
      required: true,
    },
    disableFlagging: {
      type: Boolean,
      default: false,
    },
    disableChart: {
      type: Boolean,
      default: false,
    },
    flagCallback: Function,
  },
  data() {
    return {
      PROPERTY_STATUS,
      result: null,
      loadingError: false,
    };
  },
  computed: {
    default404 () {
      return default404
    },
    fotoCard() {
      const isEmpty = !(this.property.img && this.property.img.length);

      if (isEmpty || this.loadingError) {
        return default404
      }

      return this.property.img;
    },
    propertyStatus() {
      const status = Object.values(PROPERTY_STATUS).find(
        ({ value }) => value === this.property.phaseId
      );
      return status && status.label;
    },
    ...mapState('route', ['path']),
    ...mapState('flag', ['flagged']),
    ...mapState({
      lastSearch(state, getters) {
        return getters[`${this.$route.name}/lastSearch`];
      },
    }),
    utilArea() {
      let areaString = ''
      if (this.property.typology.utilArea[0] === this.property.typology.utilArea[1]) {
        areaString = this.property.typology.utilArea[0];
      } else {
        areaString = `${this.property.typology.utilArea[0]} a ${this.property.typology.utilArea[1]}`;
      }
      return areaString
    },
    isFlagged() {
      if(this.flagged.includes(this.property.addressId)){
        return false
      } else {
        return true
      }
    },
    inputListeners() {
      const vm = this;
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          click(event) {
            vm.$emit('click', event.target.value);
          },
        }
      );
    },
  },
  methods: {
    ...mapActions('flag', ['flagProperty', 'unsetPropertyFlag']),
    ...mapMutations({
      setHighlightedItem: 'map/SET_HIGHLIGHTED_ITEM',
    }),
    setHighlightedItemLocal(item) {
      this.$emit('click:highlight');
      this.setHighlightedItem(item);
    },
    setLoadingError() {
      this.loadingError = true;
      this.$forceUpdate();
    },
    togglePropertyFlag(addressId) {
      if (this.isFlagged) {
        this.flagProperty(addressId);
      } else {
        this.unsetPropertyFlag(addressId);
      }
      this.$eventBus.$emit(`${this.$route.name}FlagCallback`, addressId);
    },
    plural(typologyData) {
      let plural = ''
      if (typologyData[0] == typologyData[1]) {
        plural = typologyData[0] > 1 ? 's' : '';
      } else {
        plural = typologyData.reduce((partial_sum, a) => partial_sum + a, 0) > 1 ? 's' : '';
      }
      return plural
    },
    typologyData(typology) {
      if(typology[0] == typology[1]) {
        return typology[0]
      } else {
        return `${typology[0]} a ${typology[1]}`
      } 
    },
    emitCondoId() {
    if (this.property && this.property.condoId) {
      this.$emit('selected-condo', this.property.condoId);
    }
  }
  },
};
</script>

<style scoped lang="scss">
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 13px;
  overflow: hidden;
  border: solid 1px #cccccc;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.typology-data {
  font-size: 11px!important;
  font-weight: 700!important;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  color: $colors-highlight-light;
  margin-bottom: 2px;
}

.typology-suffix {
  font-size: 11px!important;
  font-weight: 300!important;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  color: $colors-brand-dark
}

.typology-divider {
  border-right: 1px solid rgba(83, 97, 139, .4) !important;
}

.ofertaBossa {
  border: 2px solid #4663b9 !important;
}
.titleDiv {
  min-height: 12px;
}
.card {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  .chipsCard {
    position: absolute;
    font-size: 11px;
    font-weight: bold;
    overflow: none;
    z-index: 1;
  }
  .chipsCard1 {
    background: #eb6964;
    color: #fff;
  }
  .chipsCard4 {
    background: #7adc56;
    color: #fff;
  }
  .chipsCard6 {
    background: #f6ca6b;
  }
  .viewInMap {
    @apply flex items-center ml-auto justify-end mr-2 cursor-pointer;
    color: #3366cc;
    font-weight: 600;
    min-width: 80px;
    font-size: 11px;
  }
  .containerFlagged {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background: #F3F5FB;
    height: 27px;
    padding-top: 2px;
    .v-btn {
      color: #3366cc;
    }
  }
  .typologies {
    display: flex;  
    padding: 10px;
  }
  .containerInfFlagged {
    @apply w-full flex;
    .containerInfos {
      @apply w-full;
      font-family: 'Lato', sans-serif;
      .titulo {
        font-size: 12px;
        word-break: keep-all;
        font-weight: 800;
        line-height: 17px;
        color: #384159;
        padding: 11px 11px 0 11px;
        margin-bottom: 6px;
      }
      .text {
        padding: 0 11px;
        font-size: 11px;
        line-height: 1.4;
        color: #384159;
        margin-bottom: 2px;
        &.construction-year {
          color: #53618b;
        }
      }
    }
  }
  .containerValueADSList {
    display: flex;
    flex-direction: column;
    .attributesList {
      display: flex;
      padding: 10px;
      font-size: 11px;
      color: #3366cc !important;
      li {
        .containerDados {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

      }
    }
    .text {
      padding: 0 11px 7px 11px;
      font-size: 11px;
      font-weight: bold;
      line-height: 1.18;
      color: #384159;
    }
    .anuncio {
      font-size: 11px;
      line-height: 1.8;
      color: #384159;
      padding: 0 11px 11px 11px;
    }
  }
}
.cardDisable {
  .chipsCard1 {
    background: $colors-neutral-grey-6;
  }
  .chipsCard4 {
    background: $colors-neutral-grey-6;
  }
  .chipsCard6 {
    background: $colors-neutral-grey-6;
  }

  .viewInMap {
    span {
      color: $colors-neutral-grey-4;
    }
  }
  .containerFlagged {
    background-color: $colors-neutral-grey-1;

    .v-btn {
      color: $colors-neutral-grey-6;
    }
  }
  .containerInfFlagged {
    .containerInfos {
      .titulo {
        color: $colors-neutral-grey-5;
      }
      .text {
        color: $colors-neutral-grey-5 !important;
      }
    }
  }
  .containerValueADSList {
    .attributesList {
      color: $colors-neutral-grey-5 !important;
    }
    .text {
      color: $colors-neutral-grey-5;
    }
    .anuncio {
      color: $colors-neutral-grey-5;
    }
  }
  .typology-data {  
    color: $colors-neutral-grey-5;    
  }
  .typology-suffix {
    color: $colors-neutral-grey-5;
  }
  .checkbox-label {
    color: $colors-neutral-grey-4;
  }
}
// refatorar \/
.caracteristicas-color {
  color: #666;
}
.ContainerDadosCondominio {
  color: #666;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  .dadosCondominio {
    margin-bottom: 15px;
    p {
      margin: 0;
    }
  }
  .dadosTipologiaCondominio {
    .containerdadoslista {
      .attributes-list {
        font-size: 0.9em;
      }
    }
  }
}
.property {
  &__popup {
    .property-container {
      &:hover {
        border: 2px solid $light-gray;
      }
    }
  }
  &-container {
    font-size: 0.9em;
    overflow: hidden;
    border: 2px solid $light-gray;
    border-radius: 10px;
    box-shadow: 0 6px 8px $property-box-shadow-color;
    position: relative;
    .chipsCard {
      position: absolute;
      top: 7px;
      right: 5px;
      margin: 0 !important;
      font-size: 11px;
      font-weight: bold;
      overflow: none;
    }
    .chipsCard1 {
      background: #eb6964;
      color: #fff;
    }
    .chipsCard4 {
      background: #7adc56;
      color: #fff;
    }
    .chipsCard6 {
      background: #f6ca6b;
    }
    img {
      width: 100%;
      max-width: 100%;
      object-fit: cover;
      max-height: 8rem;
    }
    .property-descrition {
      height: 4em;
    }
    .attributes,
    .location {
      color: $black-alpha-color;
    }
    .location {
      .condo,
      .address {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .address {
        font-size: 0.9em;
        font-weight: normal;
      }
    }
    .attributes ul {
      flex: 1 1 auto;
      max-width: 100%;
    }
    .attributes-type {
      font-weight: bolder;
    }
    .attributes-list {
      font-size: 0.7em;
    }
    .price {
      font-size: 0.8vw;
      padding: 0.7em 0;
      color: $primary-blue !important;
    }
    .v-btn {
      color: $primary-blue;
      position: absolute;
      bottom: 8px;
      right: 8px;
    }
    .icon-trending-down,
    .icon-trending-plain,
    .icon-trending-up,
    .mdi-heart,
    .mdi-heart-outline {
      color: $primary-blue;
    }
    &:hover {
      border: 2px solid $primary-blue;
    }
  }
}
</style>
