<template>
  <div class="w-full h-full">
    <LGPDModal />
    <FilterArea
      :hidden="showResults || error"
      class="h-full w-full"
    >
      <LocationSelector
        slot="location-selector"
        class="w-full"
      />
      <LocationFilter
        slot="filters"
        class="w-full"
        @update:region="region = $event"
      />
      <PropertyFilters
        slot="additional-filters"
        v-model="attributesFilter"
        class="w-full"
      />
      <ButtonBar
        slot="button-area"
        v-if="!(hasData && showResults)"
        :on-clean="clearFormFilters"
        :on-confirm="debouncedSearch"
        :disabled="buttonDisabled"
        :is-loading="isLoading"
      />
    </FilterArea>
    <div
      v-if="showResults || error"
      class="h-full d-flex flex-col"
    >
      <SearchResult
        v-if="showResults || error"
        slot="search-results"
        class="flex h-full"
        :loading="loading"
        :search-summary="searchSummary"
        :search-results="result && Object.values(result).filter(item => !!item.addressLine1)"
        @reload-search="debouncedSearch()"
        @back-to-search="hideResults()"
        :filtersData="attributesFilter"
      />
      <ButtonBarResult
        class="absolute"
        v-if="showResults || error"
        :on-return="hideResults"
      />
    </div>
    <GoogleTagManager :track-event="gtmEvent" />
  </div>
</template>

<script>
import ButtonBar from '@/components/sidebar/ButtonBar.vue';
import ButtonBarResult from '@/components/sidebar/ButtonBarResult.vue'
import FilterArea from '@/components/sidebar/FilterArea.vue';
import GoogleTagManager from '@/components/google/GoogleTagManager.vue';
import LGPDModal from '@/components/LGPD/LGPDModal';
import LocationFilter from '@/components/sidebar/LocationFilter.vue';
import LocationSelector from '@/components/sidebar/LocationSelector.vue';
import PropertyFilters from '@/components/sidebar/PropertyFilters.vue';
import SearchResult from './shared/SearchResult.vue';
import searchMixin from "@/mixins/searchMixin";
import { FILTER_MODE } from '../common/constants';

export default {
  name: 'Lead',

  mixins: [searchMixin],

  components: {
    LGPDModal,
    FilterArea,
    ButtonBar,
    ButtonBarResult,
    SearchResult,
    LocationFilter,
    LocationSelector,
    PropertyFilters,
    GoogleTagManager,
  },

  beforeRouteLeave(to, from, next) {
    try {
      this.clearProperty();
      this.clearFilters();
      this.resetMapZoomLevel();
      this.setFilterMode(FILTER_MODE.RADIUS);
      this.setUseMapFilter(true);
      next();
    } catch (error) {
      console.error('Error beforeRouteLeave: ', error);
    }
  },
};
</script>
