import base from './base';

export default {
  async fetchRegionSuggestions (name, city, domain) {
    const response = await base.post(`${domain}/region/search`, {
      name,
      city,
    });

    return response && response.data;
  },
  async getRegionById (regionId, domain) {
    const response = await base.post(`${domain}/region/get/${regionId}`);

    return response && response.data;
  },
  async fetchCondoSuggestions (query, domain) {
    const response = await base.post(`${domain}/condominium/search`, {
      name: query,
    });

    return response && response.data;
  },
  async getCondo (condoId, domain) {

    const response = await base.post(`${domain}/condominium/get/${condoId}`);

    return response && response.data;
  },
  async getCondominium (condoId) {
    const response = await base.post(`/condominium/get/${condoId}`);

    return response && response.data;
  },
  async fetchRelatedCondos (condoId, domain) {
    const response = await base.get(`${domain}/condominium/similar/${condoId}`);

    return response && response.data;
  },
};
