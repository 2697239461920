<template>
  <div class="avm">
    <div class="flex">
      <AvmSidebar />
      <AvmReport />
      <div v-if="!hasResult" class="last-evaluations-wrapper w-5/6 pl-6">
        <div class="results-wrapper">
          <LastEvaluationsTable />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AvmSidebar from '@/views/avm/AvmSidebar.vue';
import AvmReport from '@/views/avm/AvmReport.vue';
import LastEvaluationsTable from '@/components/tables/lastEvaluations.vue';

export default {
  components: {
    AvmSidebar,
    AvmReport,
    LastEvaluationsTable
  },
  data () {
    return {
      gtmEvent: null
    };
  },
  computed: {
  ...mapGetters('avm', ['hasResult']),
  },
  mounted () {
    this.disableMap();
  },
  methods: {
    ...mapActions({
      disableMap: 'core/disableMap',
    }),
  },
};
</script>

<style scoped lang="scss">
.avm {
  @apply flex w-screen;
  position: absolute;
  height: calc(100vh - 75px);
  top: 75px;
  background-color: $header-bg-color;
  background-size: cover;

  .last-evaluations-wrapper {
    height: calc(100vh - 75px);
    top: 75px;
    right: 0;
    z-index: 4;
    overflow: auto;
  }

  .results-wrapper {
    @apply bg-white py-3 px-5;
  }
}

</style>
