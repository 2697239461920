<script setup>

import { computed } from 'vue';

const props = defineProps({
  hovered: {
    type: Boolean,
    default: false,
  },
});

const colors = {
  default: '#303A56',
  hovered: '#6B8CEF',
};

const color = computed(() => props.hovered ? colors.hovered : colors.default);

</script>

<template>
  <svg
    width="65"
    height="63"
    viewBox="0 0 65 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.08027 57.7463H6.05954C5.87088 57.7463 5.71795 57.8992 5.71795 58.0879V61.1749C5.71795 61.3636 5.87088 61.5165 6.05954 61.5165H63.2399C63.4286 61.5165 63.5815 61.3636 63.5815 61.1749V32.0673V17.7969V13.4277C63.5815 13.2391 63.4286 13.0861 63.2399 13.0861H55.1865C54.9978 13.0861 54.8449 13.2391 54.8449 13.4277V16.1094C54.8449 16.298 54.692 16.451 54.5033 16.451H48.7881H41.727C41.5384 16.451 41.3855 16.6039 41.3855 16.7926V30.2418C41.3855 30.4305 41.2325 30.5834 41.0439 30.5834H36.3433C36.1546 30.5834 36.0017 30.4305 36.0017 30.2418V12.0818C36.0017 11.8931 35.8487 11.7402 35.6601 11.7402H21.5379C21.3492 11.7402 21.1963 11.8931 21.1963 12.0818V29.9104V34.6212V39.332V43.7004C21.1963 43.8891 21.0434 44.042 20.8547 44.042H8.76344C8.57479 44.042 8.42185 44.1949 8.42185 44.3836V57.4047C8.42185 57.5934 8.26892 57.7463 8.08027 57.7463Z"
      fill="#E3E8F5"
    />
    <path
      d="M19.5194 38.3184V42.6868C19.5194 42.8755 19.3664 43.0284 19.1778 43.0284H7.08652C6.89787 43.0284 6.74494 43.1813 6.74494 43.37V56.3911C6.74494 56.5798 6.592 56.7327 6.40335 56.7327H4.38262C4.19396 56.7327 4.04103 56.8856 4.04103 57.0743V60.857C4.04103 61.0457 4.19396 61.1986 4.38262 61.1986H62.44H62.2462C62.0575 61.1986 61.9046 61.0457 61.9046 60.857V31.0537V16.7833V12.4141C61.9046 12.2254 61.7516 12.0725 61.563 12.0725H53.5096C53.3209 12.0725 53.168 12.2254 53.168 12.4141V15.0958C53.168 15.2844 53.015 15.4374 52.8264 15.4374H47.1112H40.0501C39.8615 15.4374 39.7085 15.5903 39.7085 15.779V29.2282C39.7085 29.4168 39.5556 29.5698 39.3669 29.5698H34.6663C34.4777 29.5698 34.3248 29.4168 34.3248 29.2282V11.0681C34.3248 10.8795 34.1718 10.7266 33.9832 10.7266H19.861C19.6723 10.7266 19.5194 10.8795 19.5194 11.0681V28.8968V33.6076V38.3184Z"
      stroke="#A5B2D9"
      stroke-width="0.850023"
    />
    <path
      d="M63.8479 61.4103L1 61.4103"
      stroke="#A5B2D9"
      stroke-width="1.27503"
      stroke-linecap="round"
    />
    <path
      d="M11.4855 47.3786V60.6298C11.4855 61.0609 11.835 61.4104 12.2661 61.4104H38.1433C38.5744 61.4104 38.9239 61.0609 38.9239 60.6298V47.3672C38.9239 47.1194 38.8063 46.8863 38.6068 46.7391L25.9917 37.4289C25.7215 37.2295 25.3541 37.2253 25.0793 37.4184L11.8172 46.74C11.6093 46.8862 11.4855 47.1244 11.4855 47.3786Z"
      :fill="color"
    />
    <rect
      x="28.6697"
      y="23.663"
      width="28.1952"
      height="37.3929"
      rx="0.815209"
      fill="white"
      :stroke="color"
      stroke-width="0.702766"
    />
    <rect
      x="33.9728"
      y="34.8084"
      width="5.79782"
      height="5.79782"
      rx="0.234375"
      transform="rotate(-90 33.9728 34.8084)"
      :fill="color"
    />
    <path
      d="M43.4601 29.8013H40.8248"
      :stroke="color"
      stroke-width="0.527075"
      stroke-linecap="round"
    />
    <path
      d="M46.6225 31.9095H40.8247"
      :stroke="color"
      stroke-width="0.527075"
      stroke-linecap="round"
    />
    <path
      d="M45.0413 34.0179H40.8247"
      :stroke="color"
      stroke-width="0.527075"
      stroke-linecap="round"
    />
    <rect
      x="33.9728"
      y="45.3907"
      width="5.79782"
      height="5.79782"
      rx="0.234375"
      transform="rotate(-90 33.9728 45.3907)"
      :fill="color"
    />
    <path
      d="M43.4601 40.3835H40.8248"
      :stroke="color"
      stroke-width="0.527075"
      stroke-linecap="round"
    />
    <path
      d="M46.6226 42.4918H40.8247"
      :stroke="color"
      stroke-width="0.527075"
      stroke-linecap="round"
    />
    <path
      d="M45.0413 44.6H40.8247"
      :stroke="color"
      stroke-width="0.527075"
      stroke-linecap="round"
    />
    <rect
      x="33.9728"
      y="55.9727"
      width="5.79782"
      height="5.79782"
      rx="0.234375"
      transform="rotate(-90 33.9728 55.9727)"
      :fill="color"
    />
    <path
      d="M43.4601 50.9656H40.8248"
      :stroke="color"
      stroke-width="0.527075"
      stroke-linecap="round"
    />
    <path
      d="M46.6226 53.0739H40.8247"
      :stroke="color"
      stroke-width="0.527075"
      stroke-linecap="round"
    />
    <path
      d="M45.0413 55.1821H40.8247"
      :stroke="color"
      stroke-width="0.527075"
      stroke-linecap="round"
    />
    <path
      d="M41.826 12.5865C41.3231 11.2472 40.6818 9.96281 39.9299 8.74752C39.6894 8.35867 39.0478 8.19647 38.7948 8.66041C38.5283 9.14899 38.2323 9.55418 38.1053 9.72726C38.1042 9.7287 38.1031 9.73041 38.1019 9.73213C37.8939 10.0175 37.8718 10.3984 38.0452 10.7061C39.5284 13.3379 40.4253 16.302 40.4531 19.3159C40.458 19.7074 40.4502 20.1919 40.421 20.581C40.3972 20.9835 40.3502 21.441 40.2832 21.8386C40.113 22.9425 39.7811 24.0359 39.4218 25.0922C39.3367 25.3146 39.2169 25.6594 39.1218 25.8755L38.9601 26.2628C38.9 26.3884 38.7553 26.7138 38.6954 26.8353C38.6335 26.9547 38.4733 27.2743 38.4088 27.3976C38.2122 27.7535 37.9901 28.1504 37.7732 28.4911C36.7534 30.0992 35.4699 31.5486 34.0076 32.7676C32.541 33.9849 30.881 34.9832 29.1138 35.6986C28.7369 35.848 28.3025 36.0037 27.9205 36.1293C27.7947 36.1692 27.4291 36.275 27.3139 36.3111C27.1735 36.3505 26.8488 36.4281 26.701 36.4699C20.3693 38.0003 13.2677 35.5046 9.25893 30.3743C6.46492 26.82 5.12787 22.0957 5.78211 17.6124C6.46065 13.0977 9.17566 9.01345 12.7524 6.22181C18.0903 2.03235 25.9933 1.35808 32.0163 4.47582C32.4238 4.68673 33.0473 4.61824 33.4012 4.32595C33.6987 4.08009 33.7543 3.9242 34.0248 3.6365C34.389 3.24965 34.2512 2.77683 33.864 2.54672C33.6557 2.42293 33.4425 2.30057 33.28 2.21833C26.0425 -1.49258 16.6174 -0.507115 10.4637 4.87041C6.79941 8.02913 4.09011 12.4126 3.35259 17.2446C2.13069 25.0456 6.19085 33.2526 13.1278 37.0182C16.684 39.0041 20.8376 39.9392 24.9029 39.5353C25.3476 39.4972 25.9254 39.4115 26.3642 39.3335C26.9973 39.2226 27.6601 39.0713 28.2779 38.8924C30.7858 38.1834 33.1516 36.9643 35.2194 35.3813C37.0245 33.9911 38.6232 32.3118 39.8935 30.42C40.3935 29.6833 40.8523 28.8809 41.25 28.0851C41.4056 27.7611 41.6205 27.2976 41.7564 26.9623C41.8681 26.7087 42.014 26.3037 42.114 26.042C42.163 25.8894 42.2985 25.4892 42.3438 25.3412C42.404 25.1109 42.4851 24.8597 42.5398 24.6299C42.7312 23.8461 42.8805 23.0111 42.9725 22.2102C43.3975 18.9624 42.9914 15.6449 41.826 12.5865Z"
      fill="#72CA72"
    />
    <path
      d="M39.893 2.66197C39.544 2.0815 38.7903 1.89359 38.2095 2.24257C38.0596 2.33267 37.9217 2.4334 37.7872 2.53679C37.1693 2.99739 36.5237 3.73892 36.0025 4.29786C31.4257 9.2337 27.2782 14.6068 23.8562 20.4196C23.5158 20.9855 23.1628 21.6067 22.8771 22.203C22.6021 22.7649 22.3092 23.3837 22.0476 23.9553C20.2477 20.6602 17.4158 17.044 14.0543 15.5641C12.7103 15.0081 11.3804 16.4234 12.0459 17.7369C12.2432 18.1241 12.4735 18.4853 12.704 18.8492C13.8276 20.6195 15.1481 22.2365 16.315 23.9696C17.7987 26.141 19.0483 28.4402 20.2569 30.77C21.0895 32.3413 23.4475 32.1055 23.944 30.3823C24.7777 28.6289 25.6341 26.8967 26.5505 25.1887C26.8431 24.6345 27.1812 24.0447 27.4621 23.4868C27.7356 22.9385 28.0431 22.3046 28.3272 21.7603C30.6842 17.1466 33.5116 12.796 36.5691 8.60327C37.0874 7.89655 37.7949 6.94 38.3252 6.241C38.6624 5.78917 39.2028 5.12312 39.4925 4.65374C39.6729 4.37042 39.842 4.08151 39.973 3.76816C40.1187 3.41945 40.1019 3.01015 39.893 2.66197Z"
      fill="#72CA72"
    />
  </svg>
</template>