import base from './base';

const getLastSearchs = async (limit) => {
  const response = await base.get(`person-data/user/lastSearches?limit=${limit}`);

  return response && response.data;
};

export default {
  getLastSearchs,
};

