<template>
  <section>
    <div
      v-if="typologies"
      class="typologies"
    >
      <div>
        <h2 class="typologies-title">
          Tipologias tipo e valores
        </h2>
        <p class="typologies-subtitle">
          Tipologias mais presentes entre os anúncios coletados
        </p>
      </div>

      <div
        v-for="(type, index) in types"
        :key="index"
        class="more"
      >
        <h2
          class="typologies-type"
          :class="{ 'mt-4': index }"
        >
          {{ type }}
        </h2>
        <div v-if="typologiesByPropertyType[type] && typologiesByPropertyType[type].hasDefaults">
          <div
            v-for="(typology, index) in typologiesByPropertyType[type].typologies"
            :key="index"
          >
            <DefaultTypologyCard
              v-if="typology.default"
              class="my-4"
              :typology="typology"
              :price-history="priceHistoryByAddress ? priceHistoryByAddress[typology.typology_id] : []"
            />
          </div>
        </div>
        <div
          class="more-inner"
          :class="{
            active:
              expandedTypologies[type] ||
              !typologiesByPropertyType[type] ||
              !typologiesByPropertyType[type].hasDefaults,
          }"
        >
          <more-typologies-table
            v-if="typologiesByPropertyType[type]"
            :typologies-rows="
              typologiesByPropertyType[type].typologies.filter(el => el.default === 0)
            "
            :price-history="priceHistoryByAddress"
          />
        </div>
        <v-btn
          v-if="
            typologiesByPropertyType[type] &&
              typologiesByPropertyType[type].hasDefaults &&
              typologiesByPropertyType[type].typologies.filter(el => el.default === 0).length
          "
          class="more-toggle"
          depressed
          outlined
          rounded
          color="#3566C9"
          @click="toggleTypologyByType(type)"
        >
          <span v-if="!expandedTypologies[type]">Ver mais tipologias de {{ type }}</span>
          <span v-else>Fechar</span>
        </v-btn>
      </div>
    </div>
    <div
      v-else
      class="w-full flex flex-col justify-center"
    >
      <h2 class="typologies-type">
        Tipologias e valores
      </h2>
      <v-skeleton-loader
        class="mx-auto"
        width="100%"
        type="list-item-three-line"
      />
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import DefaultTypologyCard from '@/components/cards/defaultTypologyCard';
import MoreTypologiesTable from '@/components/tables/more-typologies-table/more-typologies-table';

export default {
  components: {
    DefaultTypologyCard,
    MoreTypologiesTable,
  },
  props: {
    propertyStatus: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      expandedTypologies: {},
      typologiesByPropertyType: {},
      typologies: null,
    };
  },
  computed: {
    ...mapState({
      lastSearch (state, getters) {        

        return getters[`${this.$route.name}/lastSearch`];
      },
    }),
    ...mapState('property', [
      'property',
      'priceHistoryByAddress',
    ]),
    types () {
      if (!Array.isArray(this.typologies)) return [];

      return this.typologies
        .map(typology => typology.propertyType)
        .filter((type, index, array) => array.indexOf(type) === index);
    },
  },
  watch: {
    property () {
      this.loadTypologies();
      this.fetchPriceHistoryByAddress({
        addressId: this.property.addressId,
        context: this.$route.name,
      });
    },
  },
  mounted () {    
    this.loadTypologies();
    this.fetchPriceHistoryByAddress({
      addressId: this.property.addressId,
      context: this.$route.name,
    });
  },
  methods: {
    ...mapActions({
      fetchTypologies: 'property/fetchTypologies',
      fetchPriceHistoryByAddress: 'property/fetchPriceHistoryByAddress',
    }),
    
    async loadTypologies () {
      this.expandedTypologies = {};
      this.typologiesByPropertyType = {};
      
      this.typologies = await this.fetchTypologies({
        addressId: this.property.addressId,
        domain: this.$route.name,
        attributes: this.$route.name === 'seller' ? this.lastSearch.attributesFilter : this.lastSearch === undefined ? [] : this.lastSearch[1],
      });

      if (this.typologies && this.typologies.rows) {
        this.typologies = this.typologies.rows;
        this.types.forEach(type => {
          this.typologiesByPropertyType[type] = {
            hasDefaults: this.typologies.some(
              typology => typology.default && typology.propertyType === type,
            ),
            typologies: this.typologies.filter(typology => typology.propertyType === type),
          };
        });
      }
    },
    toggleTypologyByType (type) {
      this.expandedTypologies[type] = !this.expandedTypologies[type];
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped lang="scss">
.typologies {
  &-title, &-subtitle {
    font-family: 'Lato', sans-serif;
    font-style: normal;
  }
  &-title {
    @apply font-black;
      font-size: 22.5px;
      line-height: 22px;
      color: #3366CC;
      margin-bottom: 8px;
  }
  &-subtitle {
    @apply font-semibold;
    font-size: 14px;
    line-height: 14px;
    color: #707070;
  }
  &-type {
    @apply font-bold uppercase;
    font-size: 14px;
    line-height: 14px;
    color: #707070;
  }
  .more {
    @apply flex flex-col;
    margin: 24px 0;
    &-inner {
      @apply hidden;
      &.active {
        @apply block;
      }
    }

    &-toggle {
      @apply normal-case mx-auto font-bold block mt-5;
      font-size: 14px;
      min-width: 138px !important;
    }
  }
}
</style>
