import { FILTER_MODE, PROPERTY_TYPE } from '@/common/constants';
import debounce from 'lodash.debounce';
import selectContentGTM from '../utils/selectContentGTM';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { isNavigationFailure, NavigationFailureType } from 'vue-router';
import polyline from '@mapbox/polyline';
import { NAVBAR_MENU_ITEMS } from '../common/constants';
import locationMixin from '@/mixins/locationMixin';

export default {
  mixins: [locationMixin],
  data() {
    return {
      PROPERTY_TYPE,
      attributesFilter: null,
      propertyType: null,
      debouncedSearch: null,
      showResults: false,
      searchLoading: false,
      searchSummary: null,
      gtmEvent: null,
      summaryLoading: false,
      region: null,
      condo: null,
      unitFilter: null,
    };
  },

  created() {
    const { name } = this.$route;
    if(this.isSeller()) {
      this.NAVBAR_MENU_ITEMS =  NAVBAR_MENU_ITEMS;
    }
  
    this.debouncedSearch = debounce(() => this.doSearch(), 200);

    this.$nextTick(() => {
     if (this.isQuerySearch())  {
       this.searchSharedURL()
      } else {
         this.resetFiltersAndMap();
      }
    });

    this.$eventBus.$on(`${name}FlagCallback`, () => {
      this.$nextTick(() => {
        this.isSeller() ? this.applySellerSummary() : this.getSearchSummary();
      });
    });
  },

  watch: {
    filter(newValue, oldValue) {
     if (this.$route.query.filter_mode && !newValue) this.setFilter(oldValue || {});
    },
    filterMode(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.clearSearch();
        this.clearFilters();
        this.setUseMapFilter(true);
      }
    }
  },

  computed: {
    ...mapGetters('map', ['currentCity']),
    ...mapState('core', ['expandedSidebar']),
    ...mapState({
      autocompleteData(state, getters) {
        return getters[`${this.$route.name}/autocompleteData`];
      },
      result(state, getters) {
        return getters[`${this.$route.name}/result`];
      },
      filter(state, getters) {
        return getters[`${this.$route.name}/filter`];
      },
      error(state, getters) {
        return getters[`${this.$route.name}/error`];
      },
      isLoading(state, getters) {
        return getters[`${this.$route.name}/isLoading`];
      },
      filterMode(state, getters) {
        return getters[`${this.$route.name}/filterMode`];
      },
    }),
    hasData() {
      return !!(this.result && Object.values(this.result).length);
    },
    priceMaxIsLower() {
      if (!this.attributesFilter) return false;

      if (this.attributesFilter.priceMin && this.attributesFilter.priceMax) {
        return this.attributesFilter.priceMax < this.attributesFilter.priceMin;
      }

      return false;
    },
    priceNotFulfilled() {
      if (!this.attributesFilter) return false;

      return !(
        !!this.attributesFilter.priceMin || !!this.attributesFilter.priceMax
      );
    },
    areaNotFulfilled() {
      const filter = this.attributesFilter;
      if (!filter || !Object.keys(filter).includes('areaRange')) return false;
      return !!(+filter.areaRange[1] === 0 && +filter.areaRange[0] === 0);
    },
    buttonDisabled() {
      const notFilterAndResult = !this.filter && !this.result;
      const notContainsAreaOrPrice =
        this.areaNotFulfilled && this.priceNotFulfilled;

      return (
        notFilterAndResult || this.priceMaxIsLower || notContainsAreaOrPrice
      );
    },
    loading() {
      return this.isLoading || this.searchLoading || this.summaryLoading;
    },
  },

  methods: {
    ...mapActions('search', ['filterByOfferType']),
    ...mapActions('summary', ['fetchSummary', 'setRegion']),
    ...mapActions({
      clearProperty: 'property/clearProperty',
      resetMapZoomLevel: 'map/resetMapZoomLevel',
      setUseMapFilter: 'core/setUseMapFilter',
      toggleExpandSidebar: 'core/toggleExpandSidebar',
      toggleCollapseSidebar: 'core/toggleCollapseSidebar',
      search(dispatch, payload) {
        return dispatch(`${this.$route.name}/search`, payload);
      },
      clearSearch(dispatch, payload) {
        return dispatch(`${this.$route.name}/clearSearch`, payload);
      },
      clearFilters(dispatch, payload) {
        return dispatch(`${this.$route.name}/clearFilters`, payload);
      },
    }),
    ...mapMutations({
      setFilter(commit, payload) {
        return commit(`${this.$route.name}/SET_FILTER`, payload);
      },
      setFilterMode(commit, payload) {
        return commit(`${this.$route.name}/SET_FILTER_MODE`, payload);
      },
      setError(commit, payload) {
        return commit(`${this.$route.name}/SET_ERROR`, payload);
      },
    }),
    ...mapActions({
      getRegionById(dispatch, payload) {
        return dispatch(`${this.$route.name}/getRegionById`, payload);
      },
    }),
    ...mapActions({
      getCondoById (dispatch, payload) {
        return dispatch(`${this.$route.name}/getCondoById`, payload); 
      },
    }),
    handleURLparams(queryParams = {}) {
      try {
        const currentQuery = this.$route.query;
        const newQuery = queryParams instanceof URLSearchParams 
          ? Object.fromEntries(queryParams.entries()) 
          : {};

        if (!this.isSameQuery(currentQuery, newQuery)) {
          this.$router.replace({ query: newQuery }).catch(this.handleNavigationError);
        }
      } catch (error) {
        console.error('Error handling URL parameters:', error);
      }
    },
    isSameQuery(query1, query2) {
      return JSON.stringify(query1) === JSON.stringify(query2);
    },
    handleNavigationError(error) {
      if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
        console.error('Navigation error:', error);
      }
    },
    hideResults() {
      this.showResults = false;
      this.searchSummary = null;
      this.filterByOfferType();
      this.clearSearch();
      this.cleanParamsAndFilters();
    },
    cleanParamsAndFilters () {
      this.isQuerySearch() && this.handleURLparams({});
      this.clearFilters();
      this.setUseMapFilter(true);
    },
    clearFormFilters() {
      this.clearFilters();
      this.attributesFilter = null;
    },
    async getSearchSummary() {
      try {
        this.summaryLoading = true;

        this.searchSummary = await this.fetchSummary({
          attributesFilter: this.attributesFilter,
        });

        if (this.searchSummary) {
          this.gtmEvent = {
            event: 'view_result',
            has_result: +!!this.searchSummary.condominiumCount,
          };
        }
      } catch (error) {
        console.error('Error fetching search summary: ', error);
      } finally {
        this.summaryLoading = false;
      }
    },
    searchTerm() {
      const searchElementByFilterMode = {
        area: '',
        radius:
          document.querySelector('.pac-target-input') &&
          document.querySelector('.pac-target-input').value,
        region:
          document.querySelector('#regionName') &&
          document.querySelector('#regionName').value,
      };

      return searchElementByFilterMode[this.filterMode];
    },
    resetFiltersAndMap() {
     if(this.isSeller()) {
        this.setFilterMode(FILTER_MODE.CONDO);
        this.changeCurrentCity('SP');
     } else {
        this.setFilterMode(FILTER_MODE.RADIUS);
        this.setUseMapFilter(true);
     }
      this.clearSearch();
      this.clearFilters();
      this.resetMapZoomLevel();
    },
   isQuerySearch() {
      const query = { ...this.$route.query };
      return !!Object.keys(query).length > 0;
    },
    isSeller () {
      return this.$route.path === '/search/seller';
    },
    convertObjKeyToCamelCase(obj) {
      return this.convertObjectKeys(obj, key =>
        key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase())
      );
    },
    convertObjKeyToSnakeCase(obj) {
      return this.convertObjectKeys(obj, key =>
        key.replace(/([A-Z])/g, '_$1').toLowerCase()
      );
    },
    convertObjectKeys(obj, transformFn) {
      return Object.fromEntries(Object.entries(obj).map(([key, value]) => [transformFn(key), value]));
    },
    async setUrlParams() {
      if (await this.isQuerySearch()) return;

      const queryParams = new URLSearchParams();
      const { info = {} } = this.condo || {};
      const filter = this.convertObjKeyToSnakeCase(
        this.isSeller() && info
          ? {
              addressId: info.addressId,
              geolocation: info.geolocation,
              condoId: info.coodId,
              yearOfConstruction: info.yearOfConstruction,
            }
          : this.filter
      );
      const attributes = this.convertObjKeyToSnakeCase(
        this.isSeller() ? this.unitFilter : this.attributesFilter
      );

      const isEmptyArrayOrNull = arr => Array.isArray(arr) && arr.every(item => item === null);
      const formatArrayParam = value => {
        const pureValue = JSON.parse(JSON.stringify(value)); 
        return Array.isArray(pureValue) ? pureValue.join('_') + (value.length === 1 ? '_' : '') : pureValue;
      };
      const formatAndEncodeCoords = coords => {
        switch ( this.filterMode) {
          case 'region': return this.region?.id || coords;
          case 'radius':
          case 'condo': return polyline.encode([coords]);
          case 'area': return polyline.encode(coords.flat());
          default: return coords;
        }
      };

      filter.filter_mode = this.isSeller() ? 'condo' : this.filterMode;
      filter.address = ['region', 'radius'].includes(this.filterMode) 
        ? encodeURIComponent(this.locationValueData) 
        : filter.address;

      Object.entries({ ...filter, ...attributes })
        .filter(([key, value]) => key != 'point' && value != null && value !== '' && !isEmptyArrayOrNull(value) && key !== 'route_name')
        .forEach(([key, value]) => {
          queryParams.append(
            key,
            key === 'coordinates' || key === 'position' || key === 'geolocation' ? formatAndEncodeCoords(value) : formatArrayParam(value)
          );
      });

      this.handleURLparams(queryParams);
    },
    async searchSharedURL() {
      try {
        if (!this.isQuerySearch()) return;

        const query = { ...this.$route.query };
        let parsedFilter = {};
        let parsedAttributes = {};
        let condoData = {};

        const parseArrayParam = value => {
          if (typeof value === 'string' && value.includes('_')) {
            return value
              .replace(/_$/, '') 
              .split('_')
              .map(Number);
          }
          return isNaN(Number(value)) ? value : Number(value);
        };

        switch (query.filter_mode) {
          case 'region':
            try {
              const { coordinates, center } = await this.getRegionById({
                regionId: query.coordinates,
                domain: this.$route.name,
              });
              Object.assign(query, { coordinates: [coordinates], point: center });
            } catch (error) {
              console.error('Error to search region by id:', error);
            }
            break;
          case 'condo':
            try {
              query.geolocation = polyline.decode(query.geolocation).flat();
              query.address_id = parseInt(query.address_id);

              const info = await this.getCondoById({ condoId: query.address_id, domain: 'seller' });

              condoData = { info, location: {} };

              this.setFilter({ geolocation: [parseFloat(query.geolocation?.lat), parseFloat(query.geolocation?.lng)] });
            } catch (error) {
              console.error('Error trying to fetch condo:', error);
            }
            break;
          case 'area':
            query.coordinates = [polyline.decode(query.coordinates)];
            query.type = 'Polygon';
            break;
          default:
            query.position = polyline.decode(query.position).flat();
        }

        Object.entries(query).forEach(([key, value]) => {
          (this.isFilterKey(key) ? parsedFilter : parsedAttributes)[key] = parseArrayParam(value);
        });
        
        parsedFilter = {
          ...this.convertObjKeyToCamelCase(parsedFilter),
          address: this.autocompleteData,
          routeName: this.$route.name,
        };

        parsedAttributes = this.convertObjKeyToCamelCase(parsedAttributes);

        this.attributesFilter = { ...(this.attributesFilter || {}), ...parsedAttributes };
        this.unitFilter = { ...(this.unitFilter || {}), ...parsedAttributes };

        condoData.location = parsedAttributes;

        if (query.filter_mode === 'condo') {
          this.onCondoUpdated(condoData);
        } else {
          this.setFilterMode(parsedFilter.filterMode);
          this.setUseMapFilter(true);
        }

        this.setFilter(parsedFilter);
        this.doSearch(true);
      } catch (error) {
        console.error('Error parsing URL parameters:', error);
      }
    },
    isFilterKey(key) {
      return [
        'filter_mode',
        'radius',
        'position',
        'coordinates',
        'geolocation',
        'address',
        'point',
        'type',
        'address_id',
      ].includes(key);
    },
    async doSearch(isUrlSearch=false) {
      try {
        this.searchLoading = true;
        if (this.expandedSidebar) this.toggleExpandSidebar();

        const searchTerm = this.searchTerm();

        this.gtmEvent = selectContentGTM({
          attributesFilter: this.attributesFilter,
          routeName: this.$route.name,
          searchTerm,
          currentCity: this.currentCity,
          filterMode: this.filterMode,
          address: this.autocompleteData,
        });

        if (this.filterMode === 'region') this.setRegion(searchTerm);

       const tempFiler =  this.filter;

       await this.search({
          attributesFilter: this.attributesFilter,
        });
        
        this.isQuerySearch() && !this.filter &&  this.setFilter(tempFiler);

        if (!this.error) {
          this.showResults = true;
          if (Object.keys(this.result).length) {
             this.getSearchSummary();
          }
        }

        if(!isUrlSearch) this.setUrlParams();
      } catch (error) {
        console.error('Error during search operation: ', error);
        this.showResults = false;
        this.setError(error);
      } finally {
        this.searchLoading = false;
      }
    },
  },
};