<template>
  <div class="skeleton-results">
    <div class="w-full flex justify-center">
      <v-skeleton-loader
        class="mx-auto my-4 flex"
        :class="titleClass"
        width="100%"
        type="heading"
      />
    </div>
    <div class="w-full flex flex-col gap-2">
      <v-skeleton-loader
        class="mx-auto"
        width="100%"
        height="60px"
        type="card"
      />
      <v-skeleton-loader
        class="mx-auto"
        width="100%"
        height="60px"
        type="card"
      /><v-skeleton-loader
        class="mx-auto"
        width="100%"
        height="60px"
        type="card"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'SkeletonRecentQuery',
  props: {
    titleClass: {
      type: [
        String,
        Object,
      ],
      default: 'justify-center',
    },
  },
};

</script>

<style lang="scss" scoped>

.skeleton-results {
  @apply mt-4 flex flex-col justify-start items-center w-full;
}

</style>
