<template>
  <aside class="avm-sidebar">
    <div class="avm-sidebar__container">
      <div class="avm-sidebar__header">
        <div class="pt-6 pb-8">
          <Typography
            tag="h1"
            variant="header-xl-secondary"
            color="brand-dark"
            align="center"
          >
            {{ NAVBAR_MENU_ITEMS.AVM.label }}
          </Typography>
        </div>

        <div>
          <MultiSelect
            v-model="negotiationType.value"
            class="mx-2 mb-4"
            :buttons="negotiationType.options"
            :default-selection="negotiationType.value"
            :disabled="hasResult"
          />

          <div class="avm-sidebar__form-rows">
            <div class="avm-sidebar__form-rows--one-column">
              <TextField
                v-if="hasResult"
                v-model="lastSearch.endereco"
                name="address"
                label="Endereço"
                placeholder="Digite o endereço"
                type="text"
                :disabled="true"
              />

              <CustomAutocomplete
                v-else
                v-model="address.value"
                name="address"
                label="Endereço"
                placeholder="Digite o endereço"
                type="text"
                :error="address.error"
                :error-message="address.errorMessage"
                :is-disabled="hasResult"
              />
            </div>

            <div class="avm-sidebar__form-rows--two-column">
              <Select
                v-model="typology.selected"
                name="typology"
                label="Tipologia"
                placeholder="Selecione"
                :error="typology.error && typology.errorMessage"
                :items="typology.options"
                :disabled="hasResult"
                @blur="typology.validate()"
              />
            </div>

            <div class="avm-sidebar__form-rows--two-column">
              <TextField
                v-model="constructionYear.value"
                v-mask="'####'"
                name="construction-year"
                label="Ano de construção"
                placeholder="Digite o ano"
                type="text"
                :error="constructionYear.error && constructionYear.errorMessage"
                :disabled="hasResult"
                :autoComplete="false"
              />
            </div>

            <div class="avm-sidebar__form-rows--two-column">
              <TextField
                v-model="area.value"
                name="area"
                label="Área útil"
                placeholder="Digite a área"
                type="text"
                suffix="m²"
                :error="area.error && area.errorMessage"
                :disabled="hasResult"
                :autoComplete="false"
              />
            </div>

            <div class="avm-sidebar__form-rows--two-column">
              <TextField
                v-model="bedrooms.value"
                name="bedrooms"
                label="Dormitórios"
                placeholder="Digite o número"
                type="text"
                :error="bedrooms.error && bedrooms.errorMessage"
                :disabled="hasResult"
                :autoComplete="false"
              />
            </div>

            <div class="avm-sidebar__form-rows--two-column">
              <TextField
                v-model="suites.value"
                name="suites"
                label="Suítes"
                placeholder="Digite o número"
                type="text"
                :error="suites.error && suites.errorMessage"
                :disabled="hasResult"
                :autoComplete="false"
              />
            </div>

            <div class="avm-sidebar__form-rows--two-column">
              <TextField
                v-model="parkingLots.value"
                name="parkingLots"
                label="Vagas"
                placeholder="Digite o número"
                type="text"
                :error="parkingLots.error && parkingLots.errorMessage"
                :disabled="hasResult"
                :autoComplete="false"
              />
            </div>
          </div>
        </div>

        <avm-quota class="mb-3" />
        <ButtonBar
          class="w-full mt-2 mb-2"
          v-if="!hasResult"
          :on-clean="resetForm"
          :on-confirm="validateForm"
          :is-loading="isLoading"
          :disabled="!hasQuota"
        />
      </div>
      <ButtonBarResult
        v-if="hasResult"
        :on-return="resetForm"
      />
    </div>
  </aside>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex';
import { mask } from 'vue-the-mask';

import MultiSelect from '@/components/forms/MultiSelect.vue';
import CustomAutocomplete from '@/components/autocomplete/CustomAutocomplete.vue';
import AvmQuota from '@/components/user/avmQuota.vue';
import ButtonBar from '@/components/sidebar/ButtonBar.vue';
import ButtonBarResult from '@/components/sidebar/ButtonBarResult.vue';
import Typography from '@/components/base/Typography';
import TextField from '@/components/base/TextField';
import Select from '@/components/base/Select';

import { NAVBAR_MENU_ITEMS } from '@/common/constants';

export default {
  name: 'AvmSidebar',
  directives: {
    mask,
  },
  components: {
    MultiSelect,
    CustomAutocomplete,
    AvmQuota,
    ButtonBar,
    ButtonBarResult,
    Typography,
    TextField,
    Select,
  },
  data () {
    return {
      negotiationType: {
        options: [
          {
            value: 'venda',
            label: 'Quero vender',
          },
          {
            value: 'locacao',
            label: 'Quero alugar',
          },
        ],
        value: 'venda',
      },
      typology: {
        options: [
          'Apartamento',
          'Apartamento Duplex',
          'Apartamento Garden',
          'Apartamento Triplex',
          'Casa',
          'Cobertura',
          'Cobertura Duplex',
          'Cobertura Triplex',
          'Flat',
          'Loft',
          'Penthouse',
        ],
        selected: '',
        error: false,
        errorMessage: 'Selecione uma opção.',
        validate () {
          this.error = this.selected === '';
        },
      },
      constructionYear: {
        value: '',
        error: false,
        errorMessage: 'Digite o ano AAAA.',
        validate () {
          this.error = this.value < 1900 || this.value > new Date().getFullYear();
        },
      },
      area: {
        value: '',
        error: false,
        errorMessage: 'Digite um valor válido.',
        validate () {
          this.error = !this.value.length || this.value < 0;
        },
      },
      bedrooms: {
        value: '',
        error: false,
        errorMessage: 'Digite um valor válido.',
        validate () {
          this.error = !this.value.length || this.value < 0;
        },
      },
      suites: {
        value: '',
        error: false,
        errorMessage: 'Digite um valor válido.',
        validate () {
          this.error = !this.value.length || this.value < 0;
        },
      },
      parkingLots: {
        value: '',
        error: false,
        errorMessage: 'Digite um valor válido.',
        validate () {
          this.error = !this.value.length || this.value < 0;
        },
      },
      address: {
        value: {},
        error: false,
        errorMessage: 'Endereço não encontrado. Verifique a ortografia.',
        validate () {
          if (this.value.address_components && this.value.address_components.length) {
            const address = this.value.address_components;
            const filterNumber = el => el.types.includes('street_number');
            const filterEstate = el => el.types.includes('administrative_area_level_1');
            const validAdministrativeAreas = [
              'SP',
              'RJ',
            ];
            const administrativeArea = address.filter(filterEstate).length && address.filter(filterEstate)[0].short_name;

            const numberIsFilled = address.filter(filterNumber).length && address.filter(filterNumber)[0].short_name.length;

            if (!numberIsFilled) {
              this.error = true;
              this.errorMessage = 'Endereço não encontrado. Verifique a ortografia.';
            }

            const administrativeAreaIsValid = validAdministrativeAreas.includes(administrativeArea);

            if (!administrativeAreaIsValid) {
              this.error = true;
              this.errorMessage = 'Por favor, digite um endereço válido de SP ou RJ.';
            }

            if (administrativeAreaIsValid && numberIsFilled) {
              this.error = false;
            }
          }
          else {
            this.error = true;
          }
        },
      },
    };
  },
  computed: {
    ...mapState('user', ['avmQuota']),
    ...mapGetters('avm', [
      'isLoading',
      'hasResult',
      'lastSearch',
    ]),
    hasQuota () {
      if (!this.avmQuota) return false;

      return !!this.avmQuota.available;
    },
  },
  created () {
    this.NAVBAR_MENU_ITEMS = NAVBAR_MENU_ITEMS;
  },
  methods: {
    ...mapActions('avm', [
      'fetchAVM',
      'resetLastSearch',
    ]),
    addressFilter (type, extension) {
      const addressData = this.address.value;
      const filter = el => el.types.includes(type);
      const address = addressData.address_components.find(filter);

      return address[extension];
    },
    avmRequestData () {
      const addressData = this.address.value;
      const { lat, lng } = addressData.geometry.location;

      return {
        tipoavm: this.negotiationType.value,
        endereco: this.addressFilter('route', 'long_name'),
        numero: this.addressFilter('street_number', 'long_name'),
        bairro: this.addressFilter('sublocality', 'long_name'),
        cidade: this.addressFilter('administrative_area_level_2', 'long_name'),
        estado: this.addressFilter('administrative_area_level_1', 'short_name'),
        address: addressData.formatted_address,
        tipologia: this.typology.selected,
        ano_construcao: Number(this.constructionYear.value),
        area_util: this.area.value,
        dormitorios: Number(this.bedrooms.value),
        suites: Number(this.suites.value),
        vagas: Number(this.parkingLots.value),
        lat: lat(),
        lng: lng(),
      };
    },
    gtmData () {
      return {
        event: 'generate_avm',
        type: this.negotiationType.value,
        city: this.addressFilter('administrative_area_level_2', 'long_name'),
        district: this.addressFilter('sublocality', 'long_name'),
        property_type: this.typology.selected,
        bedrooms: parseInt(this.bedrooms.value, 10),
        suites: parseInt(this.suites.value, 10),
        parking_lots: parseInt(this.parkingLots.value, 10),
        util_area: parseInt(this.area.value, 10),
        year_construction: parseInt(this.constructionYear.value, 10),
      };
    },
    validateForm () {
      this.typology.validate();
      this.constructionYear.validate();
      this.area.validate();
      this.bedrooms.validate();
      this.suites.validate();
      this.parkingLots.validate();
      this.address.validate();

      if (
        !this.typology.error
        && !this.constructionYear.error
        && !this.area.error
        && !this.bedrooms.error
        && !this.suites.error
        && !this.parkingLots.error
        && !this.address.error
      ) {
        this.fetchAVM({ avmData: this.avmRequestData(), gtmData: this.gtmData() });
      }
    },
    resetForm () {
      this.negotiationType.value = 'venda';
      this.typology.selected = '';
      this.constructionYear.value = '';
      this.area.value = '';
      this.bedrooms.value = '';
      this.suites.value = '';
      this.parkingLots.value = '';
      this.address.value = null;

      this.typology.error = false;
      this.constructionYear.error = false;
      this.area.error = false;
      this.bedrooms.error = false;
      this.suites.error = false;
      this.parkingLots.error = false;
      this.address.error = false;

      this.resetLastSearch();
    },
  },
};

</script>

<style lang="scss" scoped>
.avm-sidebar {
  @apply flex h-full w-full;
  max-width: 412px;
  position: inherit;
  top: 0;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2),
              0 3px 4px 0 rgba(0, 0, 0, 0.14), 
              0 1px 8px 0 rgba(0, 0, 0, 0.12) !important;
  z-index: 4;
  background-color: #fff;

  &__container {
    @apply flex flex-col w-full h-full;
  }

  &__header {
    @apply flex flex-col flex-grow;
    @apply w-full px-2 overflow-y-auto;
  }

  &__form-rows {
    @apply pb-6 pt-4 px-3;
    @apply grid grid-cols-2 gap-x-6 gap-y-4;

    &--one-column {
      @apply col-span-2;
    }

    &--two-column {
      @apply col-span-1;
    }
  }
}

</style>
