<template>
  <v-card class="people-table">
    <v-card-title>
      <slot name="title">
        Resultado da Busca
      </slot>
      <v-spacer />
      <v-text-field
        v-model="resultFilter"
        append-icon="mdi-magnify"
        :label="placeholder"
        single-line
        hide-details
      />
    </v-card-title>
    <template v-if="resultList">
      <v-data-table
        :loading="isLoading"
        :headers="headers"
        :search="resultFilter"
        :items="processedResultList || []"
        :items-per-page="itemsPerPage"
        :footer-props="{
          'items-per-page-options': [12,],
          'page-text': `Página ${currentPage}`
        }"
        class="table w-full py-3"
        @update:page="duringPageTurning"
        @click:row="$emit('click', $event)"
      >
      <!-- aqui primeiro -->
        <template #item="{ item }">
          <tr
            v-if="item.message || item.isBlocked  || item.isMinor"
            class="cursor-pointer"
          >
            <template v-for="(header, index) in headers">
              <td v-if="index < errorStart && hasErrorStartEnd">
                {{ item[header.value] }}
              </td>
            </template>
            <template>
              <td :colspan="hasErrorStartEnd ? errorEnd - errorStart : headers.length">
                <div
                  class="isBlocked-container"
                  data-test-id="lgpd-blocked"
                >
                  <img src="@/assets/images/block-red.svg">
                  <span class="isBlocked-text">
                    {{ item.isMinor ? "Desculpe, menor de idade, dados protegidos." : (item.message ? providerPrivacyMessage : LGPDPrivacyMessage) }}
                  </span>
                </div>
              </td>
            </template>
            <template v-for="(header, index) in headers">
              <td v-if="index+1 > errorEnd && hasErrorStartEnd">
                {{ item[header.value] }}
              </td>
            </template>
          </tr>
          <tr
            v-else
            class="cursor-pointer"
            :class="{
              'hasBeenSeen': hasBeenSeen(item.SequentialId)
            }"
            @click="$emit('click', item)"
          >
            <td v-for="header in headers">
              <div
                v-if="header.value === 'Seen'"
                class="flex align-center justify-center"
              >
                <img
                  v-if="hasBeenSeen(item.SequentialId)"
                  src="@/assets/images/seen.svg"
                  data-test-id="seen"
                >
              </div>
              <div v-else>
                {{ item[header.value] }}
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </template>
  </v-card>
</template>

<script>
import { MESSAGES } from '@/common/constants';
import { mapGetters } from 'vuex';
import { getMoreDataResults } from '../../utils/getMoreDataResults';

export default {
  name: 'PeopleTable',
  props: {
    errorStart: {
      type: Number,
      default: 0,
    },
    errorEnd: {
      type: Number,
      default: 0,
    },
    isLoading: {
      default: false,
      type: Boolean,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Pesquisar',
    },
    resultList: {
      type: [
        Object,
        Array,
      ],
      default: null,
    },
    itemsPerPage: {
      type: Number,
      default: 12,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },  
  data: () => ({
    LGPDPrivacyMessage: MESSAGES.LGPDPrivacyMessage,
    providerPrivacyMessage: MESSAGES.providerPrivacyMessage,
    resultFilter: '',
  }),
  computed: {
    ...mapGetters('personData', ['seen']),
    hasErrorStartEnd () {
      return this.errorStart >= 1 && this.errorEnd >= 1;
    },
    processedResultList() {
      return this.resultList.map(item => ({
        ...item,
        isMinor: item.Age < 18 && item.DocumentFmt.length  < 15
      }));
    },
  },
  methods: {
    hasBeenSeen (id) {
      if (this.seen) {
        return this.seen.some(item => item === id);
      }

      return false;
    },
    async checkForMoreResults () {
      
      const newResults = await getMoreDataResults();

      // eslint-disable-next-line vue/no-mutating-props
      this.resultList = [
        ...this.resultList,
        ...newResults,
      ];

    },
    duringPageTurning (page) {
      this.currentPage = page;

      if ((this.resultList.length % this.itemsPerPage) === 0) {
        this.checkForMoreResults();
      }
    },
  },
  
};
</script>

<style lang="scss" scoped>

.people-table {
  @apply border-none rounded-none;
  box-shadow: none !important;

  :deep(.v-card__title) {
    @apply text-base pt-0 text-current;
    color: #707070;

    .v-label {
      font-size: 14px;
    }

    .v-input__append-inner {
      .v-icon {
        font-size: 17px;
      }
    }
  } 

  :deep(.v-data-table) {
    thead {
      :first-child th {
        font-size: 11px;
        vertical-align: top;
        padding-top: 20px;
      }

      th {
        .v-icon {
          font-size: 14px !important;
          margin-top: -3px;
          margin-left: 5px;
        }
      }
    }

    tbody td {
      font-size: 13px;
      vertical-align: middle;
    }
  }
}

:deep(.v-data-footer) {
  justify-content: flex-end;
  align-items: center;
}

.isBlocked-container {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--colors-feedback-error);
  background: #FFF;
  margin: 16px 0px;
}

.isBlocked-text {
  color: #707070;
  font-family: 'Lato';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.hasBeenSeen {
  color: var(--colors-brand-default);
}

</style>
