<template>
  <v-card class="last-evaluations-table">
    <v-card-title>
      Últimas Avaliações
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Pesquisar"
        single-line
        hide-details
      />
    </v-card-title>

    <v-data-table
      :loading="isLoading"
      :headers="headers"
      :items="filteredEvaluations"
      :search="search"
      class="table w-full py-3"
    >
      <template #item="{ item }">
        <tr @click="handleRowClick(item)" class="cursor-pointer">
          <td>{{ item.address }}</td>
          <td>{{ item.property_type }}</td>
          <td>{{ item.transaction_type }}</td>
          <td>{{ item.area }} m²</td>
          <td>{{ item.bedrooms }} Quartos</td>
          <td>{{ item.construction_year }}</td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'LastEvaluationsTable',
  data() {
    return {
      search: '',
      filters: {
        property_type: null,
        transaction_type: null,
      },
      headers: [
        { text: 'Endereço', value: 'address' },
        { text: 'Tipo de Propriedade', value: 'property_type' },
        { text: 'Tipo de Transação', value: 'transaction_type' },
        { text: 'Área', value: 'area' },
        { text: 'Quartos', value: 'bedrooms' },
        { text: 'Ano de Construção', value: 'construction_year' },
      ],
      propertyTypes: ['APARTAMENTO', 'CASA', 'SOBRADO'],
      transactionTypes: ['VENDA', 'ALUGUEL'],
    };
  },
  computed: {
    ...mapGetters('user', ['lastEvaluations', 'lastEvaluationsLoading']),
    evaluations() {
      return this.lastEvaluations || [];
    },
    isLoading() {
      return this.lastEvaluationsLoading;
    },
    filteredEvaluations() {
      return this.evaluations.filter(item => {
        return (
          (!this.filters.property_type || item.property_type === this.filters.property_type) &&
          (!this.filters.transaction_type || item.transaction_type === this.filters.transaction_type)
        );
      });
    },
  },
  methods: {
    ...mapActions('avm', [ 'fetchAVM']),
    ...mapActions('user', ['fetchLastEvaluations']),
    handleRowClick(item) {
      try {
        const formattedItem = {
          tipoavm: item.transaction_type?.toLowerCase() || '',
          tipologia: item.property_type || '',
          area_util: item.area || 0,
          ano_construcao: item.construction_year || '',
          dormitorios: item.bedrooms || 0,
          suites: item.suites || 0,
          vagas: item.parking_lots || 0,
          lat: item.latitude || '',
          lng: item.longitude || ''
        };

        if (item.address) {
          const { endereco, numero, bairro, cidade, estado } = this.parseAddress(item.address);
          Object.assign(formattedItem, { endereco, numero, bairro, cidade, estado });
        }

        this.fetchAVM({ avmData: formattedItem });
      } catch (error) {
        console.error('Error trying to fetch AVM: ', error);
      }
    },
    parseAddress(address) {
      try {
        const parts = address.split('-').map(part => part.trim());
        const [logradouro, numero] = parts[0]?.split(',').map(part => part.trim()) || ['', ''];
        const [bairro ] = parts[1]?.split(',').map(part => part.trim()) || ['', ''];
        const [cidade, estado] = address.split(',')[2].split(/[-\/]/)?.map(part => part.trim()) || ['', ''];

        return { endereco: logradouro, numero, bairro, cidade, estado };
      } catch (error) {
        console.error('Error to parse Address: ', error);
      }
    }
  },
  mounted() {
    this.fetchLastEvaluations();
  },
};
</script>

<style lang="scss" scoped>

.last-evaluations-table {
  @apply border-none rounded-none;
  box-shadow: none !important;

  :deep(.v-card__title) {
    @apply text-base pt-0 text-current;
    color: #707070;

    .v-label {
      font-size: 14px;
    }

    .v-input__append-inner {
      .v-icon {
        font-size: 17px;
      }
    }
  } 

  :deep(.v-data-table) {
    thead {
      :first-child th {
        font-size: 11px;
        vertical-align: top;
        padding-top: 20px;
      }

      th {
        .v-icon {
          font-size: 14px !important;
          margin-top: -3px;
          margin-left: 5px;
        }
      }
    }

    tbody td {
      font-size: 13px;
      vertical-align: middle;
    }
  }
}
:deep(.v-data-footer) {
  justify-content: flex-end;
  align-items: center;
}
</style>