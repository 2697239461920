import base from './base';

export default {
  async sessionCheck() {
    const check = await base.get('/sessionCheck');
    return check && check.data;
  },
  async getConsultaDadosQuota() {
    const quota = await base.get('person-data/user/getQuota');
    return quota && quota.data;
  },
  async getCurrentUser() {
    const response = await base.get('users/me');
    // @TODO: Use a custom error (ApiError)
    return response && response.data;
  },
  async getFavorites() {
    const response = await base.get('users/favorites');
    // @TODO: Use a custom error (ApiError)
    return response && response.data;
  },
  async addToFavorites(propertyId) {
    const response = await base.post('users/favorites', { propertyId });
    // @TODO: Use a custom error (ApiError)
    return response && response.data;
  },
  async removeFromFavorites(propertyId) {
    const response = await base.delete('users/favorites', { propertyId });
    // @TODO: Use a custom error (ApiError)
    return response && response.data;
  },
  async getLGPDTermsAcceptance(domain) {
    const response = await base.get(`${domain}/term`, {
    }).catch(() => {});
    return response && response.data;
  },
  async setLGPDTermsAcceptance(domain, accepted) {
    return base.post(`${domain}/term`, {
      accepted,
    }).catch(() => {});
  },
  async getLastEvaluations() {
    const evaluations = await base.get('avm-extract/user/lastEvaluations');
    return evaluations && evaluations.data;
  },
};
