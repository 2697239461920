import BuyerIcon from '@/components/icons/HomeActionBuyer';
import SellerIcon from '@/components/icons/HomeActionSeller';
import RentIcon from '@/components/icons/HomeActionRent';
import ConsultantIcon from '@/components/icons/HomeActionConsultant';
import DeveloperIcon from '@/components/icons/HomeActionDeveloper';

import HomeToolsStudiesIcon from '@/components/icons/HomeToolsStudies';
import HomeToolsCatalogIcon from '@/components/icons/HomeToolsCatalog';
import HomeToolsOffersIcon from '@/components/icons/HomeToolsOffers';
import HomeToolsDataCheckIcon from '@/components/icons/HomeToolsDataCheck';
import HomeToolsEconomicIcon from '@/components/icons/HomeToolsEconomic';
import HomeToolsCalculatorIcon from '@/components/icons/HomeToolsCalculator';
import HomeToolsAvmIcon from '@/components/icons/HomeToolsAvm';
import HomeToolsCollections from '../../components/icons/HomeToolsCollections.vue';

const tools = {
  studies: {
    icon: HomeToolsStudiesIcon,
    route: '/estudos',
    label: 'Estudo de Mercado',
    content: 'Tenha uma visão completa e detalhada de tudo que esta acontecendo em um bairro, tanto no mercado primário quanto no secundário. ',
  },
  collections: {
    icon: HomeToolsCollections,
    route: '/lista-captacoes',
    label: 'Lista sugerida para captações',
    content: 'Consulte a lista dos condomínios, com endereço e valores de referência das unidades, encontrados no mercado e que seria estratégico acrescentar na sua carteira.',
  },
  catalog: {
    icon: HomeToolsCatalogIcon,
    route: '/catalogo',
    label: 'Catálogo de lançamentos',
    content: 'Conheça quais são os empreendimentos em fase de Lançamento, Construção e Novos ou selecione através dos filtros quais se encaixam mais com o interesse do seu cliente.',
  },
  offers: {
    icon: HomeToolsOffersIcon,
    route: '/search/open',
    label: 'Busca de anúncios',
    content: 'Busque por anúncios que não puderam ser encaixados em nenhum condomínio, seja pela falta de alguma informação ou outro problema.',
  },
  dataCheck: {
    icon: HomeToolsDataCheckIcon,
    route: '/person-data',
    label: 'Consulta Dados',
    content: 'Quer entender e conhecer um pouco melhor o perfil do seu cliente? Acesse e consulte.',
  },
  economicIndex: {
    icon: HomeToolsEconomicIcon,
    route: '/indicadores-economicos',
    label: 'Indicadores econômicos',
    content: 'Acompanhe os principais indicadores econômicos e do mercado imobiliário.',
  },
  calculator: {
    icon: HomeToolsCalculatorIcon,
    route: '/calculadora',
    label: 'Calculadora de oportunidades',
    content: 'Realize uma simulação e indique para o seu cliente proprietário qual o valor de desconto sobre o valor de venda pode ser mais interessante.',
  },
  avm: {
    icon: HomeToolsAvmIcon,
    route: '/avm',
    label: 'Avaliação mercadológica | AVM',
    content: 'Avalie um imóvel nos moldes da avaliação de banco para concessão de crédito imobiliário. Esse modelo de avaliação leva em consideração a localização e tipo de construção padrão, não levando em consideração, estilo de acabamento, vista, andar,...    ',
  },
};

export const tabs = [
  {
    id: 'lead',
    label: 'Consultor',
    action: {
      icon: ConsultantIcon,
      route: '/lead',
      items: [
        'Prospecte todos os imóveis existentes e anunciados em determinada região.',
        'Saiba o preço justo e acesse informações de todos os proprietários.',
        'Extraia relatórios com todos os dados de sua pesquisa e valores encontrados.',
      ],
    },
    tools: [
      tools['offers'],
      tools['collections'],
      tools['catalog'],
      tools['studies'],
      tools['avm'],
      tools['economicIndex'],
      tools['calculator'],
      tools['dataCheck'],
    ],
    showInfo: true,
  },
  {
    id: 'buyer',
    label: 'Comprador',
    action: {
      icon: BuyerIcon,
      route: '/buyer',
      items: [
        'Selecione, compare e apresente ao cliente todas as ofertas disponíveis.',
        'Saiba o preço justo a ser pago e os fatores que definem esse valor.',
        'Extraia relatórios com todos os dados de sua pesquisa e valores encontrados.',
      ],
    },
    tools: [
      tools['studies'],
      tools['catalog'],
      tools['offers'],
    ],
    showInfo: false,
  },
  {
    id: 'seller',
    label: 'Vendedor',
    action: {
      icon: SellerIcon,
      route: '/seller',
      items: [
        'Ajude seu cliente a entender qual o volume de oferta e os valores praticados.',
        'Conte com a ferramenta de Avaliação Mercadológica do DataZap.',
        'Gere relatórios completos com todas os dados encontrados',
      ],
    },
    tools: [
      tools['studies'],
      tools['calculator'],
      tools['avm'],
    ],
    showInfo: false,
  },
  {
    id: 'rent',
    label: 'Locador',
    action: {
      icon: RentIcon,
      route: '/rent',
      items: [
        'Selecione, compare e apresente ao cliente todos os imóveis disponíveis.',
        'Assessore seu cliente a entender qual o valor praticado dentro do perfil buscado.',
        'Extraia relatórios com todos os dados de sua pesquisa e valores encontrados.',
      ],
    },
    tools: [tools['studies']],
    showInfo: false,
  },
  {
    id: 'developer',
    label: 'Incorporador',
    action: {
      icon: DeveloperIcon,
      route: '/developer',
      items: [
        'Entenda melhor a dinâmica construtiva para o bairro através dos projetos aprovados.',
        'Conheça os principais incorporadores e o perfil dos empreendimentos.',
        'Descubra os empreendimentos lançados, disponibilidades e valores para fazer comparações com outros bairros.',
        'Ajude seu cliente, caso seja de interesse o investimento.',
        'Alinhe as expectativas do cliente quanto à valorização do imóvel usado.',
        'Traga mais liquidez para sua carteira.',
      ],
    },
    tools: [
      tools['catalog'],
      tools['offers'],
    ],
    showInfo: false,
  },
];
