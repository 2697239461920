<script setup>

import MaintenanceIcon from '@/components/icons/Maintenance.vue';
import Typography from '@/components/base/Typography';
import Button from '@/components/base/Button';
import SearchFilters from '@/components/search/SearchFilters.vue';

defineEmits([
  'reload-search',
  'back-to-search',
]);

</script>

<template>
  <div
    ref="searchResultContainer"
    class="search-result flex flex-col"
  >
    <div
      ref="scrollTopTarget"
      class="search-result__cards relative flex flex-col h-full"
      @scroll.passive="scrollBehavior()"
    >
      <div
        ref="scrollElement"
        class="bg-white search-result"
      >
        <SearchSummary
          v-if="!loading && !noResults && !error"
          header-text="Resumo dos resultados"
          footer-text="Filtros utilizados"
        >
          <template #result>
            <SearchSummaryBuyer
              type="result"
              :data="resultBlock"
            />
          </template>

          <template #filter>
            <SearchSummaryBuyer
              type="filter"
              :data="summaryFilters"
            />
          </template>
        </SearchSummary>
        <div class="offers-filter" v-if="!loading && !noResults">
          <Typography
            color="brand-dark"
            variant="select-small"
          >
          Clique para filtrar
          </Typography>
          <div class="w-full flex align-center justify-center gap-2">
            <Button
              id="othersOffersButton"
              variant="primary"
              :class="otherOffers ? 'button-offer-filter' : 'button-offer-filter-clicked'"
              @click="offersFilter('other')"
            >
              Outras imobiliárias
            </Button>
            <Button
              id="bossaOffersButton"
              variant="primary"
              :class="bossaOffers ? 'button-offer-filter' : 'button-offer-filter-clicked'"
              @click="offersFilter('bossa')"
            >
              Ofertas BNSIR
            </Button>
            <Button
              id="noOffersButton"
              variant="primary"
              :class="noOffers ? 'button-offer-filter' : 'button-offer-filter-clicked'"
              @click="offersFilter('noOffers')"
            >
              Sem ofertas
            </Button>
          </div>

        </div>
        <div class="search-result__summary">
          <SearchFilters
            v-if="!isLoading && !noResults && !error"
            @withOffers="onSearchFilterWithOffers"
            @orderBy="onSearchFilterOrderBy"
          />
        </div>
        <div
          v-if="!loading && (noResults || (filterCount > 0 && !Boolean(filteredResults.length)) && !error)"
          class="flex flex-wrap justify-center py-10 px-10"
        >
          <div
            class="flex flex-col items-center mt-8 gap-8"
          >
            <MaintenanceIcon />
            <div class="flex flex-col items-center gap-4 text-center">
              <Typography
                color="brand-dark"
                variant="title-large-secondary"
              >
                Sem resultados
              </Typography>
              <Typography
                color="brand-dark"
                variant="body-large"
              >
                Por favor, tente outros parâmetros de procura para abranger mais resultados.
              </Typography>
            </div>
            <Button
              style="min-width: 0 !important; padding: 16px !important;"
              variant="secondary"
              @click="$emit('back-to-search')"
            >
              Refazer busca
            </Button>
          </div>
        </div>

        <div v-else>
          <div
            v-if="error"
            class="flex flex-col items-center mt-8 gap-8"
          >
            <MaintenanceIcon />
            <div class="flex flex-col items-center gap-4 text-center">
              <Typography
                color="brand-dark"
                variant="title-large-secondary"
              >
                Dados indisponíveis no momento
              </Typography>
              <Typography
                color="brand-dark"
                variant="body-large"
              >
                Por favor, atualize a página ou tente mais tarde
              </Typography>
            </div>
            <Button
              style="min-width: 0 !important; padding: 16px !important;"
              variant="secondary"
              @click="$emit('reload-search')"
            >
              Atualizar página
            </Button>
          </div>

          <div
            v-else-if="(loading && (!filteredResults || !filteredResults.length)) && !error"
            class="grid grid-cols-2 gap-4 mt-4 px-2"
          >
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
          </div>

          <ul
            v-else
            v-infinite-scroll="false /* loadMore */"
            class="search-result__container"
            infinite-scroll-distance="10"
            infinite-scroll-disabled="busy"
          >
            <PropertyItem
              v-for="(item, index) in filteredResults"
              :key="index"
              :property="item"
              class="max-w-lg mt-4 w-1/2"
              @click:highlight="cancelNextItemClick"
              @click="navigateToProperty(item)"
            />
          </ul>
        </div>
      </div>

      <GoogleTagManager :track-event="gtmEvent" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { formatCurrency } from "@/utils";
import debounce from 'lodash.debounce';
import infiniteScroll from 'vue-infinite-scroll';
import GoogleTagManager from '@/components/google/GoogleTagManager.vue';
import PropertyItem from '@/components/property/PropertyItem.vue';
import SearchSummary from '@/components/SearchSummary';
import SearchSummaryBuyer from '@/components/SearchSummaryBuyer';
import SkeletonCard from '@/components/skeleton/SkeletonCard.vue';
import viewProductGTM from '@/utils/viewProductGTM';
import locationMixin from '@/mixins/locationMixin';

export default {
  mixins: [locationMixin],
  directives: {
    infiniteScroll,
  },
  components: {
    GoogleTagManager,
    PropertyItem,
    SearchSummary,
    SearchSummaryBuyer,
    SkeletonCard,
  },
  props: {
    featuredCard: {
      type: Object,
      default: null,
    },
    searchResults: {
      type: Array,
      default: null,
    },
    searchSummary: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    filtersData: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      gtmEvent: null,
      preventNextClickOnItem: false,
      filteredResults: null,
      filters: {
        orderBy: 'minDistance',
        withOffers: false,
        keys: {
          distance: 'distance_from_center',
          offers: 'offersCount',
          value: 'avgValue',
        },
      },
      bossaOffers: false,
      otherOffers: false,
      noOffers: false,
      isOnTop: true,
      offersToExclude: [],
      formatCurrency,
    };
  },
  computed: {
    ...mapGetters('summary', [
      'resultBlock',
    ]),
    ...mapState('core', [
      'collapsedSidebar',
      'expandedSidebar',
      'showAdditionalFilters',
    ]),
    ...mapState('property', ['property']),
    ...mapState({
      error (state, getters) {
        return getters[`${this.$route.name}/error`];
      },
      result (state, getters) {
        return getters[`${this.$route.name}/result`];
      },
      isLoading (state, getters) {
        return getters[`${this.$route.name}/isLoading`];
      },
      filter (state, getters) {
        return getters[`${this.$route.name}/filter`];
      },
      lastSearch (state, getters) {
        return getters[`${this.$route.name}/lastSearch`];
      },
      filterMode (state, getters) {
        return getters[`${this.$route.name}/filterMode`]; 
      },
      autocompleteData (state, getters) {
        return getters[`${this.$route.name}/autocompleteData`]; 
      },
    }),
    totalItemCount () {
      return (this.result && Object.keys(this.result).length) || 0;
    },
    itemCount () {
      return (this.filteredResults && this.filteredResults.length) || 0;
    },
    scrollElement () {
      return this.$refs.scrollElement;
    },
    noResults () {
      return !(this.result && Object.keys(this.result).length);
    },
    filterCount () {
      let count = 0
      if(this.noOffers){
        count++;
      }
      if(this.bossaOffers){
        count++
      }
      if(this.otherOffers){
        count++
      }
      return count
    },
    summaryFilters () {
      const query = { ...this.$route.query };
      const locationValue = this.filterMode === 'radius' || this.filterMode === 'region'
        ? {
          label: this.locationLabelData,
          value: this.locationValueData || decodeURIComponent(query.address)
        }
        : null;

      let filters = {
        filterMode: {
          label: 'Tipo de busca',
          value: this.filterModeData
        },
        location: locationValue,
        type: {
          label: 'Tipo de imóvel',
          value: this.typeData
        },
        area: this.areaData ? {
          label: 'Área útil',
          value: this.areaData
        } : null,
        price: this.valueData ? {
          label: 'Valor',
          value: this.valueData
        } : null,
      }
      Object.keys(filters).forEach(key => {
        if (filters[key] === null) {
          delete filters[key];
        }
      });

      return filters
    },
    valueData () {
      if(this?.filtersData?.priceMin && this?.filtersData?.priceMax) {
        return `${this.formatCurrency(this.filtersData.priceMin)} - ${this.formatCurrency(this.filtersData.priceMax)}`
      } else if(this?.filtersData?.priceMin && !this?.filtersData?.priceMax) {
        return `${this.formatCurrency(this.filtersData.priceMin)}`
      } else if (!this?.filtersData?.priceMin && this?.filtersData?.priceMax) {
        return `${this.formatCurrency(this.filtersData.priceMax)}`
      }
      return null
    },
    areaData () {
      if(this?.filtersData?.areaRange[0] && this?.filtersData?.areaRange[1]) {
        return `${this.filtersData.areaRange[0]}m² - ${this.filtersData.areaRange[1]}m²`
      } else if(this?.filtersData?.areaRange[0] && !this?.filtersData?.areaRange[1]) {
        return `${this.filtersData.areaRange[0]}m²`
      } else if (!this?.filtersData?.areaRange[0] && this?.filtersData?.areaRange[1]) {
        return `${this.filtersData.areaRange[1]}m²`
      }
      return null
    },
    filterModeData () {
      switch (this.filterMode) {
        case 'radius':
          return 'Localização'
        case 'region': 
          return 'Região'
        default:
          return 'Desenho'
      }
    },
    locationLabelData () {
      switch (this.filterMode) {
        case 'radius':
          return 'Endereço'
        case 'region': 
          return 'Região'
      }
    },
    ageData () {
      if(this?.filtersData?.propertyAge[0] && this?.filtersData?.propertyAge[1]) {
        return `R$ ${this.filtersData.propertyAge[0]} - R$ ${this.filtersData.propertyAge[1]}`
      } else if(this?.filtersData?.propertyAge[0] && !this?.filtersData?.propertyAge[1]) {
        return `R$ ${this.filtersData.propertyAge[0]}`
      } else if (!this?.filtersData?.propertyAge[0] && this?.filtersData?.propertyAge[1]) {
        return `R$ ${this.filtersData.propertyAge[1]}`
      }
      return null
    },
    typeData () {
      switch (this.filtersData.propertyType) {
        case 1:
          return 'Apartamentos'
        case 2:
          return 'Casas'
        case 3:
          return 'Coberturas'
      }
    },
    statusData () {
      const statusArray = this.filtersData.sort().map(status => {
        switch (status) {
          case 1:
            return 'Pronto'
          case 4:
            return 'Lançamento'
          case 6:
            return 'Construção'
        }
      })
      return statusArray.join(' - ')
    },
  },
  watch: {
    searchResults (results) {
      this.noOffers = false
      this.otherOffers = false
      this.bossaOffers = false

      this.filteredResults = this.getFilteredResults(results);
      if (!results) {
        this.isOnTop = true;
      }
    },
  },
  created () {
    this.navigateToProperty = debounce(this.navigateToProperty.bind(this), 200);

    this.$eventBus.$on('clickOffersTrigger', (filter) => {
        this.offersFilter(filter);
      });
  },
  beforeDestroy() {
     this.$eventBus.$off('clickOffersTrigger');
  },
  methods: {
    ...mapActions({
      toggleExpandSidebar: 'core/toggleExpandSidebar',
      fetchProperty: 'property/fetchProperty',
      fetchAddresses (dispatch, payload) {
        return dispatch(`${this.$route.name}/fetchAddresses`, payload);
      },
    }),
    ...mapActions('search', ['setShowWithoutOffers', 'filterByOfferType']),
    onSearchFilterWithOffers (withOffers) {
      this.filters.withOffers = withOffers;
      this.filteredResults = this.getFilteredResults(this.searchResults);
      this.setShowWithoutOffers(!withOffers);
    },
    onSearchFilterOrderBy (orderBy) {
      this.filters.orderBy = orderBy;
      this.filteredResults = this.getFilteredResults(this.searchResults);
    },
    hasNoOffers (offer) {
      return !offer.offerType.has_offers
    },
    hasOtherOffers (offer) {
      return !this.hasNoOffers(offer) && !offer.offerType.is_own_real_estate
    },
    hasBossaOffers (offer) {
      return !this.hasNoOffers(offer) && offer.offerType.is_own_real_estate
    },
    getFilteredResults (results) {
      if (!results || !results.length) return [];

      let orderBy;
      let minToMax = true;

      switch (this.filters.orderBy) {
      case 'minDistance':
        orderBy = this.filters.keys.distance;
        minToMax = true;
        break;
      case 'maxDistance':
        orderBy = this.filters.keys.distance;
        minToMax = false;
        break;
      case 'minOffers':
        orderBy = this.filters.keys.offers;
        minToMax = true;
        break;
      case 'maxOffers':
        orderBy = this.filters.keys.offers;
        minToMax = false;
        break;
      case 'minValue':
        orderBy = this.filters.keys.value;
        minToMax = true;
        break;
      case 'maxValue':
        orderBy = this.filters.keys.value;
        minToMax = false;
        break;
      default:
        orderBy = this.filters.keys.distance;
        minToMax = true;
      }

      let newResults = [...results].sort((first, second) => {
        const firstValue = first[orderBy];
        const secondValue = second[orderBy];

        if (minToMax) {
          return firstValue - secondValue;
        }

        return secondValue - firstValue;
      });

      if (this.filters.withOffers) {
        newResults = newResults.filter(item => item[this.filters.keys.offers]);
      }

      this.offersToExclude = [];
      let noOffersToExclude = [];      
      let otherOffersToExclude = [];   
      let bossaOffersToExclude = [];

      if(this.filterCount > 0 && this.filterCount < 3){
        if(this.filterCount === 1){
          if(this.noOffers) {
            noOffersToExclude = newResults.map(item => {
              if(!this.hasNoOffers(item)) {
                return item.addressId
              }
            }).filter(Boolean)
            newResults = newResults.filter(item => this.hasNoOffers(item))
          } else if(this.bossaOffers) {
            bossaOffersToExclude = newResults.map(item => {
              if(!this.hasBossaOffers(item)) {
                return item.addressId
              }
            }).filter(Boolean)
            newResults = newResults.filter(item => this.hasBossaOffers(item))
          } else if(this.otherOffers) {
            otherOffersToExclude = newResults.map(item => {
              if(!this.hasOtherOffers(item)) {
                return item.addressId
              }
            }).filter(Boolean)
            newResults = newResults.filter(item => this.hasOtherOffers(item))
          }
        } else if(this.filterCount === 2) {
          if(!this.noOffers) {
            noOffersToExclude = newResults.map(item => {
              if(this.hasNoOffers(item)) {
                return item.addressId
              }
            }).filter(Boolean)
            newResults = newResults.filter(item => !this.hasNoOffers(item))
          } else if(!this.bossaOffers) {
            bossaOffersToExclude = newResults.map(item => {
              if(this.hasBossaOffers(item)) {
                return item.addressId
              }
            }).filter(Boolean)
            newResults = newResults.filter(item => !this.hasBossaOffers(item))
          } else if(!this.otherOffers) {
            otherOffersToExclude = newResults.map(item => {
              if(this.hasOtherOffers(item)) {
                return item.addressId
              }
            }).filter(Boolean)
            newResults = newResults.filter(item => !this.hasOtherOffers(item))
          }
        }
      }
      
      this.offersToExclude = bossaOffersToExclude.concat(otherOffersToExclude, noOffersToExclude)

      return newResults;
    },
    offersFilter (filter) {
      switch (filter) {
        case 'bossa':
          this.bossaOffers = !this.bossaOffers
          break;
        case 'other':
          this.otherOffers = !this.otherOffers
          break;
        case 'noOffers':
          this.noOffers = !this.noOffers
          break;
      }
      this.filteredResults = this.getFilteredResults(this.searchResults);
      this.filterByOfferType(this.offersToExclude);

    },
    cancelNextItemClick () {
      this.preventNextClickOnItem = true;
    },
    async loadMore () {
      const pageSize = 16;
      const mapIds
        = (this.result
          && Object.entries(this.result)
            .filter(([
              _,
              value,
            ]) => !value.addressLine1) // eslint-disable-line no-unused-vars
            .map(([addressId]) => addressId))
        || [];
      const pageIds = mapIds.slice(0, pageSize);

      await this.fetchAddresses(this.$route.name, pageIds || []);
    },
    navigateToProperty (item) {
      if (this.preventNextClickOnItem) {
        this.preventNextClickOnItem = false;

        return;
      }

      if (this.expandedSidebar) {
        this.toggleExpandSidebar();
      }

      if (!this.property || item.addressId !== this.property.addressId) {
        this.gtmEvent = viewProductGTM(item);
      }

      this.fetchProperty({
        addressId: item.addressId,
        domain: this.$route.name,
      });
    },
    scrollBehavior () {
      const searchContainerResultHeight = this.$refs.searchResultContainer.offsetHeight;
      const expandableContainerHeight = this.$refs.scrollTopTarget.scrollHeight;
      
      if ( expandableContainerHeight > searchContainerResultHeight) {
        this.isOnTop = this.$refs.scrollTopTarget.scrollTop === 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.offers-filter {
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
  margin: 8px;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 16px 8px;
  flex-shrink: 0;
  flex-wrap: wrap;
  border-radius: 5px;
  border: 1px solid var(--colors-neutral-grey-2);
}

.button-offer-filter, .button-offer-filter-clicked {
  min-width: 85px;
  max-width: 165px;
  height: 32px;
  padding: 8px 12px;
  border-radius: 50px;
  border: 1px solid var(--colors-highlight-darker);

  &:hover { 
    background: var(--colors-highlight-default)!important;
    border: 1px solid var(--colors-highlight-default)!important;
    color: var(--colors-neutral-white)!important;
  }
}

.button-offer-filter {
  background: var(--colors-highlight-darker)!important;
}

.button-offer-filter-clicked {
  background: var(--colors-neutral-white)!important;
  color: var(--colors-highlight-light)!important;
  border: 1px solid var(--colors-highlight-light)!important;

  &:focus {
    background: var(--colors-neutral-white)!important;
    color: var(--colors-highlight-light)!important;
  }
  &:hover { 
    background: var(--colors-highlight-default)!important;
    border: 1px solid var(--colors-highlight-default)!important;
    color: var(--colors-neutral-white)!important;
  }
}
.search-result {
  &__summary {
    display: grid;
    grid-template-rows: 0fr;
    gap: 24px;
    transition: grid-template-rows .25s;
    padding: 8px 0px 16px 8px;
  }

  &__cards {
    overflow: auto;
    background-color: #FFF;
    margin-bottom: 60px;
    padding: 0 8px 8px;
  }

  &__container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      padding: 0;
      margin-bottom: 32px;

      & > li:nth-child(-n + 2) {
      margin-top: 0 !important;
    }
  }
}

:deep(.attributesList li span) {
  font-size: 9px;
  font-weight: bolder;
}

:deep(.property .divider) {
  margin: 0 7px;
  height: 35px;
  border-right: 1px solid #777;
}

:deep(.property:last-child) {
  margin-right: auto;
}

</style>
